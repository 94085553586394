.view {
  width: 120px !important;
  height: 140px !important;
}
.image {
  object-fit: cover !important;
  width: 100% !important;
  height: 100% !important;
}
.details {
  width: calc(100% - 120px);
  padding-inline-start: 13px;
  display: flex;
  flex-direction: column;
}
.title {
  font-size: 16px !important;
}
.text {
  font-size: 13px !important;
  color: #000;
  font-weight: 500;
}
.top {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.actions {
  :global .btn-normal {
    font-size: 12px !important;
    margin-bottom: 7px;
  }
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}

@media (max-width: 767.98px) {
  .view {
    width: 100px !important;
    height: 130px !important;
  }
  .details {
    width: calc(100% - 100px);
  }
  .title {
    font-size: 13px !important;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (max-width: 991.98px) {
}

@media (max-width: 1199.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
}

@media (min-width: 1400px) {
}
