.container {
  width: 60px;
  height: 60px;
  z-index: 1000;
  position: fixed;
  bottom: 40px;
  right: 40px;
  background: #25d366 !important;
  border-radius: 50% !important;
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none !important;
  outline: none !important;
  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
}
.container:hover {
  /* background: #eee !important; */
  border: none !important;
  outline: none !important;
}
.container:active {
  transform: scale(0.8);
  border: none !important;
  outline: none !important;
}
.image {
  width: 55% !important;
}

body[class="rtl-dir"] .container {
  left: 15px;
  right: auto;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}

@media (max-width: 767.98px) {
  .container {
    width: 50px;
    height: 50px;
    bottom: 60px;
    right: 10px;
  }
  .image {
    width: 50% !important;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (max-width: 991.98px) {
}

@media (max-width: 1199.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
}

@media (min-width: 1400px) {
}
