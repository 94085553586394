.container {
  padding-top: 40px;
}
.grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 12.52rem;
  gap: 3rem;
}
.title {
  line-height: 1.8;
  font-size: 14px !important;
  margin-bottom: 1px;
  font-weight: 600 !important;
}
.details {
  display: flex;
  align-items: center;
  padding-inline-end: 50px;
  padding-bottom: 40px;
}
.downloadText {
  line-height: 2.15;
  font-weight: 500;
  color: #000;
  font-size: 17px;
  margin-bottom: 20px;
}
.btn {
  margin-inline-end: 20px;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}

@media (max-width: 767.98px) {
  .grid {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 0rem;
  }
  .details {
    padding: 30px 15px 55px;
    text-align: center;
  }
  .downloadText br {
    content: "";
  }
  .btn {
    margin-inline: 10px;
  }
  .downloadText {
    line-height: 2;
    font-weight: 400;
    /* color: #000; */
    font-size: 14px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

@media (max-width: 991.98px) {
}

@media (min-width: 576px) and (max-width: 991.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (max-width: 1199.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
}

@media (min-width: 1400px) {
}
