.container {
  padding-bottom: 30px;
}
.card {
  position: relative;
  /* height: 500px; */
}
.action {
  padding-top: 40px;
  text-align: center;
  /* position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 35px 15px; */
}
.avatar {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
  aspect-ratio: unset !important;
}
.actionTitle {
  font-weight: 600;
  font-size: 14px;
  padding: 10px 17px;
  border-radius: 4px;
  background: #6c2d93;
  color: #ffffff;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}
/* .grid :nth-child(1n + 2) {
  grid-column: auto / span 2;
}
.grid :nth-child(5n + 2) {
  grid-column: 3;
} */

.card:nth-child(1),
.card:nth-child(2),
.card:nth-child(6),
.card:nth-child(7) {
  /* padding: 50px; */
  /* background: #000; */
  /* grid-column: span 2; */
}
.card:nth-child(3),
.card:nth-child(4),
.card:nth-child(5),
.card:nth-child(8),
.card:nth-child(9),
.card:nth-child(10) {
  /* padding: 50px; */
  /* background: #080; */
  /* grid-column: 3 / span2; */
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}

@media (max-width: 767.98px) {
  .grid {
    grid-template-columns: 1fr 1fr !important;
  }
  div .container {
    padding-top: 1.5rem !important;
    padding-bottom: 1rem !important;
  }
  .action {
    padding: 15px 15px;
  }
  .actionTitle {
    font-size: 12px;
    padding: 9px 11px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

@media (max-width: 991.98px) {
}

@media (min-width: 576px) and (max-width: 991.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  div .container {
    padding-top: 2rem !important;
    padding-bottom: 1rem !important;
  }
}

@media (max-width: 1199.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
}

@media (min-width: 1400px) {
}
