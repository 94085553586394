.subscribe {
  padding: 20px 0;
  width: 100%;
  max-width: 400px;
}
.input::placeholder {
  color: #ccc;
  vertical-align: initial;
  line-height: 2;
  overflow: visible;
}
.input {
  color: #fff;
  width: 100%;
  border: 1px solid var(--main-border);
  outline: none;
  background: none;
  padding: 10px 17px;
}
/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}

@media (max-width: 767.98px) {
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (max-width: 991.98px) {
}

@media (max-width: 1199.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
}

@media (min-width: 1400px) {
}
