.container {
  margin-bottom: 40px;
}
.block {
  background: var(--main-color);
  color: #fff;
  display: inline-flex;
  padding: 20px 32px;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}
.view {
  display: flex;
  align-items: center;
  justify-content: center;
}
.view .icon {
  font-size: 50px;
  font-weight: 500;
}
.details {
  padding-inline-start: 20px;
}
.title {
  font-weight: 600;
  font-size: 17px;
  margin-bottom: 2px;
}
.text {
  font-size: 15px;
  line-height: 1.5;
  color: #eee;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}

@media (max-width: 767.98px) {
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (max-width: 991.98px) {
}

@media (max-width: 1199.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
}

@media (min-width: 1400px) {
}
