.block {
  display: flex;
  align-items: center;
  /* flex-wrap: wrap; */
}
.btnCard {
  display: inline-flex;
  align-items: center;
}
.btnCard:not(:last-of-type) {
  margin-inline-end: 7px;
}
div .btn {
  padding: 0 !important;
}

.imgBtn {
  height: 24px !important;
  width: 24px !important;
  max-width: 24px !important;
  object-fit: cover !important;
  transition: all 0.2s cubic-bezier(0.42, 0, 0.58, 1);
  /* border-radius: 50% !important; */
}
.btnCard:hover .imgBtn {
  transform: scale(1.2);
}
.title {
  font-weight: 600;
  font-size: 13px;
  color: #000;
  text-align: start !important;
  margin-bottom: 7px;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}

@media (max-width: 767.98px) {
  .imgBtn {
    height: 17px !important;
    width: 17px !important;
    max-width: 17px !important;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (max-width: 991.98px) {
}

@media (max-width: 1199.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
}

@media (min-width: 1400px) {
}
