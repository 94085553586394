.error {
  /* min-height: 100vh; */
  /* margin-top: -115px; */
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 50px 20px 110px;
}
.error404 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.errorTitle {
  font-weight: bold;
  font-size: 25px;
  margin-top: 30px;
  margin-bottom: 30px;
}
.image {
  width: 40% !important;
}
