.headerSwitcher {
  background: var(--main-color);
  padding: 0 10px;
}
.item a {
  color: #fff;
  font-size: 17px;
  padding: 6px 11px;
  display: inline-block;
  font-weight: 500;
}
.item a:hover {
  color: #999;
}
.item a {
  /* &:global.active {
    background: #fff !important;
    color: var(--main-color);
  } */
  background: #fff !important;
  color: var(--main-color);
}
.socialBtn {
  color: #fff;
  margin: 0 7px;
  display: flex;
  align-items: center;
}
.socialBtn:hover {
  color: #ddd;
}
.platformItem {
  display: flex;
  align-items: center;
  color: #fff;
  margin-inline: 5px;
  /* font-weight: 600; */
}
.platformItemImg {
  height: 25px !important;
  width: auto !important;
  object-fit: contain !important;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}

@media (max-width: 767.98px) {
  .item a {
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: 15px;
  }
  .platformItemImg {
    height: 20px !important;
  }
  .platformItem {
    margin-inline: 1px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (max-width: 991.98px) {
}

@media (max-width: 1199.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
}

@media (min-width: 1400px) {
}
