.card {
  border: 1px solid var(--main-border);
  padding: 15px;
  transition: all 0.1s ease-in-out;
  background: hsl(0, 0%, 100%);
  position: relative;
  z-index: 10;
}
.card:hover {
  border-color: #000;
  /* transform: scale(1.1); */
}
.image {
  object-fit: contain;
  width: 100% !important;
  height: 100% !important;
  aspect-ratio: 1;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}

@media (max-width: 767.98px) {
  .card {
    padding: 10px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .card {
    padding: 10px;
  }
}

@media (max-width: 991.98px) {
}

@media (max-width: 1199.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
}

@media (min-width: 1400px) {
}
