.grid {
  /* display: grid;
  grid-template-columns: 1.2fr 0.8fr;
  grid-gap: 12.52rem;
  gap: 3rem; */
  display: flex;
}
.view {
  position: relative;
  width: 748px;
}
.details {
  width: calc(100% - 748px);
  padding-inline-start: 30px;
}
.price {
  font-weight: 500;
  font-size: 24px;
  color: #f94c43;
}
div .withoutOldPrice {
  font-weight: 500;
  font-size: 24px;
}
.mainTitle {
  font-size: 15px;
  font-weight: 600;
}
.titleGray {
  font-size: 14px;
  color: #939393;
  margin-inline-end: 10px;
}
.oldPrice {
  color: #939393;
  font-weight: 400;
  margin: 0 15px;
  text-decoration: line-through;
}
.preview {
  height: 600px;
  width: 100%;
  /* border: 1px solid; */
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  text-align: center;
  position: relative;
}
.previewImg {
  height: 100% !important;
  width: auto !important;
  object-fit: contain !important;
}
.fullScreenBtn {
  position: absolute;
  right: 20px;
  top: 20px;
  background: var(--main-color);
  color: #fff;
  border: none;
  font-size: 33px;
  display: inline-flex;
  align-items: center;
  padding: 5px;
  border-radius: 6px;
  transition: all 0.1s ease-in-out;
}
.fullScreenBtn:hover {
  transform: scale(1.2);
}
.fullScreenBtn:active {
  opacity: 0.7;
}
.previewImg > div {
  height: 100%;
}
.previewImg > div img {
  object-fit: contain !important;
  height: 100% !important;
}

body[class="rtl-dir"] .fullScreenBtn {
  right: auto;
  left: 20px;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}

@media (max-width: 767.98px) {
  .grid {
    display: block;
  }
  :global .page-content-product {
    padding-top: 0 !important;
  }
  .grid > div {
    /* width: calc(100% + 24px); */
    /* margin-inline: -12px; */
    width: 100%;
  }
  .details {
    padding-inline-start: 0;
  }
  .price {
    font-size: 17px;
  }
  .preview {
    height: 60vh;
    margin-top: 15px;
  }
  .fullScreenBtn {
    right: 10px;
    top: 10px;
    font-size: 23px;
  }
  body[class="rtl-dir"] .fullScreenBtn {
    right: auto;
    left: 10px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  :global .page-content-product {
    padding: 30px 0 70px !important;
  }
  .grid {
    /* grid-template-columns: 1.1fr 0.9fr;
    gap: 20px; */
    display: block;
  }
  .grid > div {
    width: 100%;
  }
  .details {
    padding-inline-start: 0;
  }
}

@media (max-width: 991.98px) {
}

@media (max-width: 1199.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  :global .page-content-product {
    padding: 30px 0 70px !important;
  }
  .view {
    width: 500px;
  }
  .details {
    width: calc(100% - 500px);
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .view {
    width: 650px;
  }
  .details {
    width: calc(100% - 650px);
  }
}

@media (min-width: 1400px) {
}
