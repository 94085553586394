.searchBar {
  min-width: 400px;
}
.searchBarGroup {
  display: flex;
  align-items: center;
  border: 1px solid var(--main-border);
  padding: 10px 11px;
}

.inputSearch {
  outline: none;
  width: 100%;
  border: none;
  padding: 0;
}
.searchBarGroup .icon {
  font-weight: 700;
  font-size: 18px;
  margin-inline-end: 9px;
  color: #999;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}

@media (max-width: 767.98px) {
  .searchBar {
    min-width: auto !important;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (max-width: 991.98px) {
}

@media (max-width: 1199.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
}

@media (min-width: 1400px) {
}
