.cardLink {
  padding-block: 5px;
  display: block;
  position: relative;
}
.card {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  background: #fff;
  margin-inline: 7px;
  padding: 13px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  border-radius: 8px;
  text-align: center;
}
.details {
  padding-inline-end: 18px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.title {
  font-weight: 600;
  font-size: 17px;
}
.brandView {
  display: flex;
  justify-content: center;
}
.image {
  /* width: auto !important;
  max-width: 200px !important;
  height: 130px !important;
  object-fit: contain;
  transition: all 0.3s ease-in-out; */
  width: auto !important;
  max-width: 140px !important;
  height: 140px !important;
  object-fit: contain !important;
  transition: all 0.3s ease-in-out;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}
.card:hover .image {
  transform: scale(1.1);
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .image {
    max-width: 70px !important;
    height: 70px !important;
  }
}

@media (max-width: 767.98px) {
  .card {
    padding: 7px;
    margin-inline: 3px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .image {
    max-width: 100px !important;
    height: 100px !important;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .card {
    padding: 10px;
  }
}

@media (max-width: 991.98px) {
}

@media (max-width: 1199.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
}

@media (min-width: 1400px) {
}
