.logo {
  max-width: 170px !important;
  width: 100% !important;
  margin-inline: auto;
  margin-bottom: 22px;
}
.breadcrumbBtn {
  color: #999 !important;
  padding-inline: 0 !important;
}
.breadcrumbBtn:hover {
  color: #999;
}
.breadcrumbBtn.active {
  color: var(--main-color) !important;
}
body[class="rtl-dir"] .nextIcon {
  transform: scaleX(-1);
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}

@media (max-width: 767.98px) {
  .logo {
    width: 160px !important;
  }
  .breadcrumbBtn {
    font-size: 13px;
    padding-inline: 0px !important;
  }
  .breadcrumb li {
    margin-inline: 1px !important;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (max-width: 991.98px) {
}

@media (max-width: 1199.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
}

@media (min-width: 1400px) {
}
