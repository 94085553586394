.card {
  /* aspect-ratio: 1; */
  position: relative;
  border-radius: 0;
  overflow: hidden !important;
  /* height: 400px !important; */
}
.card:hover .image {
  transform: scale(1.08);
  filter: blur(1px);
}
.image {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
  transition: all 0.15s ease-in-out;
  object-position: center;
}
.title {
  /* background-image: linear-gradient(
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.00709) 10.47%,
    rgba(0, 0, 0, 0.02755) 19.56%,
    rgba(0, 0, 0, 0.06016) 27.58%,
    rgba(0, 0, 0, 0.1037) 34.81%,
    rgba(0, 0, 0, 0.15697) 41.57%,
    rgba(0, 0, 0, 0.21875) 48.13%,
    rgba(0, 0, 0, 0.28782) 54.79%,
    rgba(0, 0, 0, 0.36296) 61.85%,
    rgba(0, 0, 0, 0.44297) 69.61%,
    rgba(0, 0, 0, 0.52662) 78.36%,
    rgba(0, 0, 0, 0.6127) 88.39%,
    rgba(0, 0, 0, 0.7)
  ); */
  color: #fff;
  font-size: 25px;
  font-weight: 500;
  /* position: absolute;
  bottom: 0;
  left: 0; */
  width: 100%;
  padding: 25px;
  transform: translateY(100%);
  transition: all 0.25s ease-in-out;
  position: absolute;
  bottom: 0;
  left: 0;
  text-shadow: 1px 1px 2px #000000;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: var(--main-color); */
  transition: all 0.15s cubic-bezier(0.17, 0.67, 0.83, 0.67);
  /* opacity: 0; */
}
.card:hover .overlay {
  opacity: 0.9;
}
.overlay:hover .title {
  transform: translateY(0);
}
.onSale {
  background-color: #ff3838;
  color: #fff;
  position: absolute;
  top: 20px;
  left: 20px;
  line-height: 1;
  font-weight: 600;
  z-index: 10;
  font-size: 15px;
  padding: 9px 15px;
  border-radius: 50px;
  display: flex;
  align-items: center;
}
.onSale .icon {
  margin-inline-start: 7px;
  transform: scale(1.45);
}
.viewMore {
  display: inline-flex;
  align-items: center;
  padding: 7px 20px;
  padding-inline-end: 7px;
  border-radius: 50px;
  background: var(--main-color);
  color: #fff;
  line-height: 1;
  font-size: 14px;
  font-weight: 500;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}
.viewMore .icon {
  margin-inline-start: 10px;
  width: 35px;
  height: 35px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 15px;
  font-weight: 600;
  color: var(--main-color);
  background: #ffff;
}
body[class="rtl-dir"] .viewMore {
  left: 15px;
  right: auto;
}
body[class="rtl-dir"] .viewMore .icon {
  display: inline-flex !important;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}

@media (max-width: 767.98px) {
  .title {
    font-size: 15px;
    padding: 11px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (max-width: 991.98px) {
}

@media (max-width: 1199.98px) {
  .viewMore {
    padding: 4px 13px;
    padding-inline-end: 4px;
    font-size: 11px;
    top: 10px;
    right: 10px;
  }
  .viewMore .icon {
    margin-inline-start: 6px;
    width: 24px;
    height: 24px;
    font-size: 11px;
  }
  body[class="rtl-dir"] .viewMore {
    left: 10px;
    right: auto;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
}

@media (min-width: 1400px) {
}
