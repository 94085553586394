.headerWrapper {
  /* min-height: calc(100vh - 158px);
  display: flex;
  flex-direction: column; */
  position: relative;
  overflow: hidden;
}
.mainVideo {
  display: flex;
  flex-direction: column;
}
.videoPlayer {
  width: 100% !important;
  height: 98vh !important;
  /* object-fit: cover !important; */
}
/* .videoPlayer {
  video {
    object-fit: cover !important;
    object-position: center !important;
  }
} */
.videoPlayer video {
  object-fit: cover !important;
}
.videoTitle {
  color: #fff;
  font-size: 27px;
  font-weight: 500;
  position: absolute;
  bottom: 30px;
  width: 100%;
  z-index: 12;
  /* padding: 400px 35px 35px; */
}
.overlay {
  position: absolute;
  background-image: linear-gradient(
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.00709) 10.47%,
    rgba(0, 0, 0, 0.02755) 19.56%,
    rgba(0, 0, 0, 0.06016) 27.58%,
    rgba(0, 0, 0, 0.1037) 34.81%,
    rgba(0, 0, 0, 0.15697) 41.57%,
    rgba(0, 0, 0, 0.21875) 48.13%,
    rgba(0, 0, 0, 0.28782) 54.79%,
    rgba(0, 0, 0, 0.36296) 61.85%,
    rgba(0, 0, 0, 0.44297) 69.61%,
    rgba(0, 0, 0, 0.52662) 78.36%,
    rgba(0, 0, 0, 0.6127) 88.39%,
    rgba(0, 0, 0, 0.7)
  );
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}

@media (max-width: 767.98px) {
  .videoPlayer {
    /* height: 40vh !important; */
    height: auto !important;
    aspect-ratio: 1;
  }
  .videoPlayer iframe {
    height: 60vw;
  }
  .videoTitle {
    font-size: 18px;
  }
  .videoTitle {
    bottom: 20px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

@media (max-width: 991.98px) {
}

@media (min-width: 576px) and (max-width: 991.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .videoPlayer {
    height: 80vh !important;
  }
  .videoPlayer iframe {
    height: 50vw;
  }
}

@media (max-width: 1199.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
}

@media (min-width: 1400px) {
}
