.swiperHeader {
  padding: 7px !important;
  margin: -12px;
  width: 100% !important;
  max-width: 100% !important;
  padding-inline-start: 0 !important;
}
.swiperSlide {
  height: 170px !important;
  cursor: pointer;
  /* box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1); */
  border: 1px solid var(--main-border);
  /* width: 100% !important; */
}
.swiperSlide .videoPlayer {
  width: 100% !important;
  max-width: 100% !important;
  height: 100% !important;
  max-height: 100% !important;
}
.image {
  height: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  cursor: pointer;
}
:global .view-slider-pagination {
  display: inline-flex;
  padding-block: 40px;
  justify-content: center;
}

:global .view-slider-pagination .swiper-pagination-bullet {
  border-radius: 100% !important;
  border: 2px solid var(--main-color) !important;
  transition: all 0.2s ease-in-out !important;
  width: 14px;
  height: 14px;
  margin: 3px !important;
  background: #e3e3e3;
  border: none !important;
  box-shadow: none !important;
}

:global .swiper-pagination-bullet-active {
  background: var(--main-color) !important;
  border: 2px solid var(--main-color) !important;
}

.thumbContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.9);
  /* visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 300ms, opacity 300ms;
  z-index: -100; */
  display: none;
}
.thumbContainer.active {
  /* visibility: visible;
  opacity: 1;
  transition: visibility 0s linear 0s, opacity 300ms;
  z-index: 100; */
  z-index: 10000;
  display: block !important;
}
.imgThumb {
  width: auto !important;
  height: 100vh !important;
  width: auto !important;
  margin: auto !important;
  object-fit: cover;
}
.btnActions {
  position: absolute;
  bottom: 100px;
  z-index: 100;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  :global div {
    position: unset;
    margin: 0 17px;
  }

  :global .btn-arrow-exit {
    transform: scale(1.2);
  }
}
.videoPlayerPreview {
  height: 100vh !important;
  width: 100% !important;
}
body[class="rtl-dir"] .btnActions {
  flex-direction: row-reverse;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}

@media (max-width: 767.98px) {
  .swiperSlide {
    height: 85px !important;
    max-height: 85px;
  }

  /* :global .view-slider-pagination {
    position: static;
    width: 100%;
    display: flex !important;
    flex-direction: row;
    transform: none !important;
    padding: 25px 15px 7px;
    justify-content: center;
  }
  .btnActions {
    bottom: 50px;
  } */
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .swiperSlide {
    height: 120px !important;
  }
  :global .view-slider-pagination {
    left: -35px;
    top: 200px !important;
  }
}

@media (max-width: 991.98px) {
}

@media (max-width: 1199.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  :global .view-slider-pagination {
    position: static;
    display: flex;
    flex-direction: row;
    transform: none !important;
    width: auto;
    justify-content: center;
    /* padding: 20px; */
  }
  .swiperSlide {
    height: 120px !important;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
}

@media (min-width: 1400px) {
}
