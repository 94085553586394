.container {
  padding: 110px 0;
}
.block {
  width: 100%;
  max-width: 400px;
  margin: auto;
}
.accountCheck {
  font-size: 15px;
  padding: 30px 0;
}
.accountCheckLink {
  color: #000;
  font-weight: 600;
  margin: 0 5px;
}
.suggestedLink {
  font-weight: 600;
  font-size: 14px;
}
.suggestedLink a {
  color: #000;
}

.otpInput > div {
  display: grid !important;
  grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
  grid-gap: 12.52rem;
  gap: 30px;
}

.otpInput > div input {
  width: 100% !important;
  aspect-ratio: 1;
  font-size: 32px;
  font-weight: 600;
  border: 2px solid #cecece !important;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}

@media (max-width: 767.98px) {
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (max-width: 991.98px) {
}

@media (max-width: 1199.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
}

@media (min-width: 1400px) {
}
