.container {
  padding-bottom: 30px;
}
.grid {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-gap: 10px;
  gap: 10px;
}
.innerPadding {
  padding-top: 3rem;
  padding-bottom: 1.5rem;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .grid {
    grid-template-columns: repeat(3, 1fr);
    gap: 5px;
  }
}

@media (max-width: 767.98px) {
  .innerPadding {
    padding-bottom: 1.7rem;
    padding-top: 1.5rem;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 991.98px) {
}

@media (min-width: 576px) and (max-width: 991.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .grid {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media (max-width: 1199.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .grid {
    grid-template-columns: repeat(6, 1fr);
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
}

@media (min-width: 1400px) {
}
