.container {
  padding-bottom: 80px;
}
/* .container * {
  font-family: "Rounded Elegance", sans-serif !important;
} */
.block {
  padding-top: 20px;
}
.grid {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-gap: 12.52rem;
  gap: 25px;
}
.toolbar {
  /* overflow-x: auto; */
  /* overflow-y: hidden; */
  padding-top: 20px;
}
.tabsContainer {
  display: inline-flex;
  flex-wrap: wrap;
  width: 100%;
  /* border-bottom: 1px solid var(--main-border); */
}
.tabBtn {
  padding: 8px 17px;
  position: relative;
  font-size: 15px;
  font-weight: 600;
  margin-inline-end: 7px;
  margin-bottom: 7px;
  white-space: nowrap;
  background: #f7f7f7;
  cursor: pointer;
  border-radius: 12px;
}
.tabBtn:after {
  position: absolute;
  bottom: -1.5px;
  left: 0;
  content: "";
  width: 100%;
  height: 3px;
  background: transparent;
}
.active {
  background: var(--main-color) !important;
  color: #fff !important;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}

@media (max-width: 767.98px) {
  .container .header {
    display: block !important;
  }
  .searchBar {
    min-width: auto !important;
  }
  .mainTitle {
    text-align: start;
    font-size: 19px !important;
    margin-bottom: 7px;
  }
  .grid {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 10px;
  }
  .toolbar {
    overflow-x: auto;
    overflow-y: hidden;
    padding: 0;
    border-bottom: 1px solid var(--main-border);
  }
  .tabsContainer {
    flex-wrap: nowrap;
  }
  .tabBtn {
    margin-bottom: 0;
    border-radius: 0;
    background: none;
    padding-inline: 0;
    padding-block: 15px;
    margin-inline-end: 16px;
    font-size: 14px;
    position: relative;
    color: #000;
    font-weight: 500;
  }
  .tabBtn.active {
    background: none !important;
    color: var(--main-color) !important;
    font-weight: 600;
  }
  .block {
    padding-top: 7px;
  }
  .tabBtn:after {
    content: "";
    height: 5px;
    width: 100%;
    bottom: -3px;
    background: transparent;
  }
  .tabBtn.active:after {
    background: var(--main-color);
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .grid {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 15px;
  }
  .tabBtn {
    font-size: 13px;
  }
}

@media (max-width: 991.98px) {
}

@media (max-width: 1199.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  /* .grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 20px;
  } */
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
}

@media (min-width: 1400px) {
}
