.grid {
  display: grid;
  grid-auto-flow: dense; /* dont forget this to fill all the tracks */
  grid-auto-rows: 100px; /* the height of one row */
  grid-gap: 5px;
}
.grid :nth-child(5n + 1),
.grid :nth-child(5n + 4) {
  grid-column: span 3; /* 1st and 4th need 2 columns */
}
.grid :nth-child(5n + 5) {
  grid-area: span 2/4; /* 5th at column 4 taking 2 rows */
}
/* .grid :nth-child(5n + 3) {
  grid-column: 1; 
} */

/**/
.grid {
  max-width: 600px;
  margin: auto;
  counter-reset: num;
}
.grid * {
  border: 2px solid;
  font-size: 30px;
  box-sizing: border-box;
  font-family: sans-serif;
  display: grid;
  place-content: center;
}
.grid *:before {
  content: counter(num);
  counter-increment: num;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}

@media (max-width: 767.98px) {
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

@media (max-width: 991.98px) {
}

@media (min-width: 576px) and (max-width: 991.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (max-width: 1199.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
}

@media (min-width: 1400px) {
}
