.searchBar {
  position: fixed;
  z-index: 600;
  padding: 17px 0;
  background: #fff;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  border-top: 1px solid var(--main-border);
  overflow-y: auto;
}
.searchContent {
  width: 100%;
  :global .icon {
    font-size: 19px;
    color: var(--color-light);
  }
}
.searchContent input {
  width: 100%;
  height: 45px;
  font-size: 21px;
  margin-inline: 20px;
  outline: none;
  border: none;
}
.exitIcon:hover {
  cursor: pointer;
  color: #000;
}
.searchResuts {
  background: #fff;

  padding-top: 20px;
  padding-bottom: 20px;
}

.backdrop {
  position: absolute;
  top: 100%;
  left: 0;
  height: 100vh;
  width: 100%;
  /* visibility: hidden;
  opacity: 0;
  z-index: -50; */
  z-index: 500;
  opacity: 0.5;
  visibility: visible;
  background: #363636;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}
.backdrop.active {
  z-index: 50;
  opacity: 0.5;
  visibility: visible;
}
.image {
  aspect-ratio: 1.5;
}
.showAll {
  border-bottom: 1px solid var(--main-border);
  padding-bottom: 10px;
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
  color: #999;
  cursor: pointer;
}
.searchBox {
  border: 1px solid var(--main-border);
  padding-block: 5px;
  width: 100%;
}
.searchIcon {
  font-weight: 600 !important;
  padding-inline-start: 17px;
}
.exitIcon {
  font-weight: 600 !important;
  margin-inline-start: 20px;
}
.emptySearchContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
}
.emptySearch {
  width: 100% !important;
  max-width: 650px !important;
}
.minMsg {
  margin-top: 5px;
}
.minMsg.active {
  opacity: 0;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}

@media (max-width: 767.98px) {
  .searchContent {
    :global .icon {
      font-size: 17px;
    }
  }
  .searchBar {
    padding-block: 8px;
  }
  .searchContent input {
    height: 34px;
    font-size: 14px;
  }
  .searchContent input {
    margin-inline: 7px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (max-width: 991.98px) {
}

@media (max-width: 1199.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
}

@media (min-width: 1400px) {
}
