.card {
  padding: 15px;
  transition: all 0.1s ease-in-out;
  background: #fff;
  box-shadow: rgba(14, 63, 126, 0.06) 0px 0px 0px 1px,
    rgba(42, 51, 70, 0.01) 0px 1px 1px -0.5px,
    rgba(42, 51, 70, 0.01) 0px 2px 2px -1px,
    rgba(42, 51, 70, 0.01) 0px 3px 3px -1.5px,
    rgba(42, 51, 70, 0.01) 0px 5px 5px -2.5px,
    rgba(42, 51, 70, 0.01) 0px 10px 10px -5px,
    rgba(42, 51, 70, 0.01) 0px 24px 24px -8px;
  border-radius: 12px;

  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}
.card:hover {
  border-color: #000;
  transform: scale(1.05);
}
.image {
  object-fit: cover !important;
  width: 100% !important;
  height: 100% !important;
  aspect-ratio: 1;
  border-radius: 8px;
}
.title {
  font-size: 17px;
  font-weight: 600;
  padding-top: 15px;
}
.cardBody {
  padding-top: 15px;
}
.childRow {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
}
.childImage {
  /* width: 50px !important;
  height: 50px !important; */
  aspect-ratio: 1;
  object-fit: cover !important;
  display: inline-block;
}
.childsGrid {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  grid-gap: 12.52rem;
  gap: 10px;
}
.cardLink {
  /* display: inline-block; */

  display: flex;
  flex-direction: column;
  flex: 1;
}
.childRowMore {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--main-color);
  color: #fff;
  box-shadow: none;
  border: none;
}
.childRowMore .icon {
  font-weight: 600;
  font-size: 20px;
}
.childRowMore:hover {
  background: var(--active-main-color);
  color: #fff;
}
.childRowMore:active {
  opacity: 0.7;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .childRowMore .icon {
    font-size: 15px;
  }
}

@media (max-width: 767.98px) {
  .card {
    padding: 8px;
  }
  .title {
    font-size: 15px;
    padding-top: 8px;
  }
  .cardBody {
    padding-top: 6px;
  }
  .childsGrid {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 5px;
  }
  .subCategoryRow {
    display: none;
  }
  .subCategoryRow:nth-child(-n + 3) {
    display: block;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  /* .childsGrid {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 5px;
  }
  .subCategoryRow {
    display: none;
  }
  .subCategoryRow:nth-child(-n + 3) {
    display: block;
  } */
}

@media (max-width: 991.98px) {
}

@media (max-width: 1199.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
}

@media (min-width: 1400px) {
}
