.card {
  display: flex;
  width: 100%;
  cursor: pointer;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);
}
.card:nth-child(even) {
  flex-direction: row-reverse;
}
.cardImg,
.cardTitle {
  width: 50%;
}
.cardImg {
  overflow: hidden;
}
.cardImgView {
  aspect-ratio: 2;
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
  transition: all 0.2s ease-in-out;
}
.cardTitle {
  padding: 20px;
  font-weight: 600;
  font-size: 27px;
  text-align: center;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card:hover .cardImgView {
  transform: scale(1.2);
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}

@media (max-width: 767.98px) {
  .cardTitle {
    padding: 10px;
    font-weight: 600;
    font-size: 13px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .cardTitle {
    font-size: 20px;
  }
  .cardImgView {
    aspect-ratio: 1.5;
  }
}

@media (max-width: 991.98px) {
}

@media (max-width: 1199.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
}

@media (min-width: 1400px) {
}
