@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?u0t4ma');
  src:  url('fonts/icomoon.eot?u0t4ma#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?u0t4ma') format('truetype'),
    url('fonts/icomoon.woff?u0t4ma') format('woff'),
    url('fonts/icomoon.svg?u0t4ma#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-maximize:before {
  content: "\e947";
}
.icon-price-tag:before {
  content: "\e946";
}
.icon-hanger:before {
  content: "\e945";
}
.icon-user-2:before {
  content: "\e93f";
}
.icon-cart-2:before {
  content: "\e940";
}
.icon-reset-2:before {
  content: "\e941";
}
.icon-note:before {
  content: "\e942";
}
.icon-store-2:before {
  content: "\e943";
}
.icon-search1:before {
  content: "\e944";
}
.icon-share-4:before {
  content: "\e93e";
}
.icon-coupon:before {
  content: "\e93d";
}
.icon-eye:before {
  content: "\e90d";
}
.icon-handbag:before {
  content: "\e93c";
}
.icon-heart-full:before {
  content: "\e93a";
}
.icon-heart-empty:before {
  content: "\e93b";
}
.icon-sale:before {
  content: "\e939";
}
.icon-bookmark-full:before {
  content: "\e937";
}
.icon-bookmark-empty:before {
  content: "\e938";
}
.icon-play:before {
  content: "\e936";
}
.icon-chevron-left:before {
  content: "\e903";
}
.icon-chevron-right:before {
  content: "\e904";
}
.icon-discounts:before {
  content: "\e935";
}
.icon-home:before {
  content: "\e90e";
}
.icon-category:before {
  content: "\e914";
}
.icon-marketplace:before {
  content: "\e934";
}
.icon-edit:before {
  content: "\e907";
}
.icon-ordered:before {
  content: "\e905";
}
.icon-call:before {
  content: "\e923";
}
.icon-cart:before {
  content: "\e92a";
}
.icon-copy:before {
  content: "\e92b";
}
.icon-location-pin-svgrepo-com1:before {
  content: "\e92c";
}
.icon-lock:before {
  content: "\e92d";
}
.icon-logout:before {
  content: "\e92e";
}
.icon-redirection:before {
  content: "\e92f";
}
.icon-share-2:before {
  content: "\e930";
}
.icon-share-3:before {
  content: "\e931";
}
.icon-trash:before {
  content: "\e932";
}
.icon-user-circle:before {
  content: "\e933";
}
.icon-diagonal-arrows:before {
  content: "\e929";
}
.icon-clock:before {
  content: "\e927";
}
.icon-location-pin-svgrepo-com:before {
  content: "\e928";
}
.icon-tiktok:before {
  content: "\e925";
}
.icon-website:before {
  content: "\e924";
}
.icon-snapchat:before {
  content: "\e926";
}
.icon-grid:before {
  content: "\e918";
}
.icon-terrible-grid:before {
  content: "\e919";
}
.icon-double-grid:before {
  content: "\e922";
}
.icon-search:before {
  content: "\e921";
}
.icon-menu:before {
  content: "\e91f";
}
.icon-cart-bag:before {
  content: "\e920";
}
.icon-plus:before {
  content: "\e91c";
}
.icon-minus:before {
  content: "\e91d";
}
.icon-whatsapp:before {
  content: "\e91e";
}
.icon-pintrest:before {
  content: "\e91a";
}
.icon-twitter:before {
  content: "\e91b";
}
.icon-chevron-down:before {
  content: "\e900";
}
.icon-chevron-up:before {
  content: "\e901";
}
.icon-arrow-bottom:before {
  content: "\e902";
}
.icon-cancel:before {
  content: "\e906";
}
.icon-correct:before {
  content: "\e908";
}
.icon-delivery:before {
  content: "\e909";
}
.icon-dollar:before {
  content: "\e90a";
}
.icon-exclamation:before {
  content: "\e90b";
}
.icon-facebook:before {
  content: "\e90c";
}
.icon-location:before {
  content: "\e90f";
}
.icon-marker:before {
  content: "\e910";
}
.icon-phone:before {
  content: "\e911";
}
.icon-search-2:before {
  content: "\e912";
}
.icon-share:before {
  content: "\e913";
}
.icon-instagram:before {
  content: "\e915";
}
.icon-youtube:before {
  content: "\e916";
}
.icon-email:before {
  content: "\e917";
}
