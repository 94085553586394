.container {
  padding-bottom: 30px;
}
.card {
  position: relative;
  /* height: 500px; */
}
.action {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 35px 15px;
}
.avatar {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
  aspect-ratio: 1 !important;
}
.grid {
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  grid-gap: 12.52rem;
  gap: 13px;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 7px;
  }
}

@media (max-width: 767.98px) {
  div .container {
    padding-top: 1.5rem !important;
    padding-bottom: 1rem !important;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .grid {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 7px;
  }
}

@media (max-width: 991.98px) {
}

@media (min-width: 576px) and (max-width: 991.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .grid {
    grid-template-columns: repeat(6, minmax(0, 1fr));
    gap: 10px;
  }
}

@media (max-width: 1199.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .grid {
    grid-template-columns: repeat(6, minmax(0, 1fr));
    gap: 10px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
}

@media (min-width: 1400px) {
}
