.key {
  font-weight: 600;
  font-size: 14px;
}

.cardTitle {
  color: #444;
  font-size: 17px;
  font-weight: 600;
  padding-bottom: 12px;
  margin-bottom: 22px;
  border-bottom: 1px solid var(--main-border);
}

.actions {
  :global .btn-normal {
    font-size: 15px;
    font-weight: 500;
    margin-inline-end: 20px;
  }
}
/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}

@media (max-width: 767.98px) {
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (max-width: 991.98px) {
}

@media (max-width: 1199.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
}

@media (min-width: 1400px) {
}
