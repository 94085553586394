.responsiveNavbar {
  position: fixed;
  bottom: 8px;
  z-index: 100;
  background: #fff;
  /* width: calc(100% - 25px); */
  width: calc(100% - 30px);
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.13), 0 1px 1px 0 rgba(0, 0, 0, 0.11);
  display: none;
}
.responsiveNavbarBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-block: 7px;
  padding-inline: 7px;
}
.resRow {
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 1;
  padding: 7px 12px;
  border-radius: 50px;
}
.resRow .icon {
  font-weight: 600;
  font-size: 19px;
  color: #444;
}
.title {
  display: none;
  margin-inline-start: 5px;
  white-space: nowrap;
}
.resRow {
  &:global.active {
    background: #6c2d9342;
    color: var(--main-color);
  }
  &:global.active .icon {
    color: var(--main-color) !important;
  }

  &:global.active {
    .title {
      display: block !important;
    }
  }
}
.resRow.active {
  background: #6c2d9342;
  color: var(--main-color);
}
.resRow.active .icon {
  color: var(--main-color);
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}

@media (max-width: 767.98px) {
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

@media (max-width: 991.98px) {
  .responsiveNavbar {
    display: block;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (max-width: 1199.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
}

@media (min-width: 1400px) {
}
