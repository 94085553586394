footer {
  background: var(--main-color);
  padding: 50px 0 50px;
}
.grid {
  display: grid;
  grid-template-columns: 0.9fr 0.9fr 1fr 1.3fr;
  grid-gap: 12.52rem;
  gap: 4rem;
}
.title {
  font-weight: 600;
  color: #fff;
  font-size: 17px;
  margin-bottom: 25px;
}
.listItem {
  margin-bottom: 12px;
}
.listLink,
.description {
  color: #fff;
  font-size: 15px;
}
.listLink:hover {
  color: #999 !important;
}
.description {
  line-height: 1.8;
}
.icon {
  color: #fff;
  margin-inline-end: 17px;
  font-size: 18px;
}
.bottom {
  padding-top: 10px;
}
.copyright {
  color: #fff;
  padding-top: 20px;
  font-size: 14px;
}
.platformsContainer {
  padding-top: 30px;
}
.platforms {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  /* justify-content: center; */
}
.platformItemImg {
  display: inline-block;
  height: 25px !important;
  width: auto !important;
  margin-inline-end: 7px;
}
.platformItem {
  display: flex;
  align-items: center;
  background: #fff;
  margin-inline-end: 10px;
  margin-top: 7px;
  padding: 8px 12px;
  border-radius: 6px;
  font-weight: 600;
  font-size: 15px;
}
.platformItem:hover {
  background: #eee;
}
.platformItem:active {
  opacity: 0.7;
  transform: scale(0.9);
}
.footerBottom {
  background: #1f1137;
}
.footerText {
  color: #fff;
  padding: 24px 0;
  text-align: center;
  font-size: 13px;
}
.poweredBy {
  margin-inline-start: 7px;
  color: #fff;
  text-decoration: underline !important;
  font-weight: 500;
}
.poweredBy:hover {
  color: #eee;
}
.poweredBy:active {
  opacity: 0.7;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}

@media (max-width: 767.98px) {
  footer {
    padding: 44px 0 60px;
  }
  .grid {
    grid-template-columns: 1fr;
    gap: 30px;
  }
  .title {
    margin-bottom: 8px;
    font-size: 15px;
  }
  .icon {
    font-size: 16px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .grid {
    grid-template-columns: 1fr 1fr;
    gap: 30px;
  }
  footer {
    padding-bottom: 60px;
  }
  .title {
    margin-bottom: 10px;
  }
  footer {
    padding: 50px 0 50px;
  }
}

@media (max-width: 991.98px) {
}

@media (max-width: 1199.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  footer {
    padding: 60px 0 80px;
  }
  .grid {
    gap: 1.5rem;
  }
  .platformItem {
    font-size: 12px;
  }
  .platformItemImg {
    height: 18px !important;
    margin-inline-end: 5px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
}

@media (min-width: 1400px) {
}
