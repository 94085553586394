.container {
  padding-bottom: 50px;
}

.accountDetails {
  padding-bottom: 80px;
}
.logoutBtn {
  padding: 0;
}
.grid {
  display: grid;
  grid-template-columns: 1.4fr 0.6fr;
  grid-gap: 12.52rem;
  gap: 3rem;
}
.cardTitle {
  color: #444;
  font-size: 17px;
  font-weight: 600;
  padding-bottom: 12px;
  margin-bottom: 22px;
  border-bottom: 1px solid var(--main-border);
}
.orders {
  overflow-x: auto;
}
.text b {
  color: #000;
  font-weight: 600 !important;
  text-transform: capitalize;
}

.infoRow {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
}
.infoRowKey {
  min-width: 250px;
  font-weight: 500;
  font-size: 16px;
}
.infoRowValue {
  color: #666;
}
.avatar {
  position: relative;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  /* overflow: hidden; */
}
.avatarImage {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
  border-radius: 50% !important;
}
.avatarFile {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  opacity: 0;
}
.avatarIcon {
  width: 27px;
  height: 27px;
  background: var(--main-color);
  color: #fff;
  box-shadow: 0px 3px 6px 0px rgba(140, 149, 159, 0.15);
  position: absolute;
  bottom: 1px;
  left: 6px;
  /* transform: translateX(-50%); */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 101;
  font-weight: 700;
  cursor: pointer;
}
/* .avatarIcon:hover {
  background: #eee;
} */
.avatarIcon:active {
  transform: scale(0.9);
}
.avatarIcon .icon {
  font-weight: 600;
}
.flagRow {
  display: flex;
  align-items: center;
}
.flag {
  width: 30px !important;
  height: 30px !important;
  object-fit: cover !important;
}
.ml {
  margin-inline-start: 8px;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}

@media (max-width: 767.98px) {
  .grid {
    grid-template-columns: 1fr;
  }
  div .updateProfileHeader {
    display: block !important;
  }
  .updateProfileBackBtn {
    margin-bottom: 20px;
  }
  .updateProfileTabs {
    display: flex;
    justify-content: center;
  }
  .accountActions {
    text-align: end;
    display: flex;
    justify-content: end;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .accountAction {
    margin-inline: 0;
    margin-bottom: 10px;
  }
  .logoutBtn {
    white-space: nowrap;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .grid {
    grid-template-columns: 1.3fr 0.7fr;
    gap: 20px;
  }
}

@media (max-width: 991.98px) {
}

@media (max-width: 1199.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
}

@media (min-width: 1400px) {
}
