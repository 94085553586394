.player {
  width: 100% !important;
  margin-bottom: 50px;
}
div .videoPlayer {
  width: 100% !important;
  max-width: 100% !important;
  min-height: 600px !important;
  object-fit: cover !important;
}
.videoPlayer video {
  object-fit: cover !important;
}
.categoriesContainer {
  padding-bottom: 40px;
}
.categoriesGrid {
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  grid-gap: 12.52rem;
  gap: 15px;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}

@media (max-width: 767.98px) {
  div .videoPlayer {
    min-height: 200px !important;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

@media (max-width: 991.98px) {
}

@media (min-width: 576px) and (max-width: 991.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (max-width: 1199.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
}

@media (min-width: 1400px) {
}
