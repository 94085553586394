.counter {
  height: 38px;
  border: 1px solid var(--main-border);
  position: relative;
}
.inputCounter {
  width: 33px;
  font-size: 16px;
  font-weight: 500;
  color: #333;
  border: none;
  outline: none;
  text-align: center;
}
.counterBtn {
  font-size: 17px;
  color: #999;
  margin: 0 9px;
  cursor: pointer;
}
.counterBtn .icon {
  font-weight: 600;
}
.counterBtn:hover {
  color: #000;
}
.counterBtn:active {
  opacity: 0.5;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}

@media (max-width: 767.98px) {
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (max-width: 991.98px) {
}

@media (max-width: 1199.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
}

@media (min-width: 1400px) {
}
