.container {
  padding-bottom: 80px;
}
.categoriesContainer,
.brandsContainer,
.productsContainer,
.promosContainer {
  padding-block: 50px 20px;
}
.categoriesGrid,
.brandsGrid,
.productsGrid,
.promosGrid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 12.52rem;
  gap: 15px;
}
.brandsGrid {
  grid-template-columns: repeat(6, minmax(0, 1fr));
  gap: 0px;
}
.productsGrid {
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 15px;
}
.promosGrid {
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 15px;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}

@media (max-width: 767.98px) {
  .container .header {
    display: block !important;
  }
  .searchBar {
    min-width: auto !important;
  }
  .mainTitle {
    text-align: start;
    font-size: 19px !important;
    margin-bottom: 7px;
  }
  .grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 10px;
  }
  .categoriesGrid,
  .brandsGrid,
  .productsGrid,
  .promosGrid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 10px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 15px;
  }
  .categoriesGrid,
  .brandsGrid,
  .productsGrid,
  .promosGrid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 15px;
  }
}

@media (max-width: 991.98px) {
}

@media (max-width: 1199.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 20px;
  }
  .categoriesGrid,
  .productsGrid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 15px;
  }
  .brandsGrid {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 15px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
}

@media (min-width: 1400px) {
}
