.container {
  padding-bottom: 50px;
}
:global .default-btn {
  display: flex;
  align-items: center;
  font-weight: 500;
}

:global .default-btn .icon {
  font-weight: 600;
  font-size: 10px;
  display: flex;
  align-items: center;
  position: relative;
  top: 1px;
}
.grid {
  display: grid;
  grid-template-columns: 1.5fr 0.5fr;
  grid-gap: 15px;
  gap: 40px;
}
.cardTitle {
  border-bottom: 1px solid var(--main-border);
  padding-bottom: 10px;
}
.content {
  margin-top: 30px;
}
.resetContent {
  max-width: 350px;
  width: 100%;
}
.resetRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.resetRow:not(:last-of-type) {
  border-bottom: 1px solid var(--main-border);
}
.orderContainer {
  overflow-x: auto;
}
.orderNoteTitle {
  font-weight: 600;
  font-size: 17px;
  margin-bottom: 2px;
}
.orderNotetext {
  font-size: 16px;
  font-weight: 500;
  color: #666;
}
.title {
  font-size: 17px;
  font-weight: 600;
  color: #000;
}
.phoneText {
  font-weight: 500;
  font-size: 16px;
  color: #000;
}
.paymentMethod {
  margin-top: 15px;
  background: var(--main-color);
  color: #fff;
  display: inline-block;
  padding: 12px 20px;
  border-radius: 6px;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}

@media (max-width: 767.98px) {
  .grid {
    grid-template-columns: 1fr;
    gap: 40px;
  }
  div .addressDetails {
    padding-bottom: 1rem !important;
  }
  div .reset {
    display: block !important;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  div .reset {
    display: block !important;
  }
}

@media (max-width: 991.98px) {
}

@media (max-width: 1199.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
}

@media (min-width: 1400px) {
}
