.card {
  margin-right: 25px;
  transition: all 0.01s cubic-bezier(0.175, 0.885, 0, 1);
  background-color: #fff;
  width: 100%;
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border: none;
  display: flex;
  flex-direction: column;
  margin: 0;
}
.card:hover {
  /* box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05); */
  transform: scale(1.02, 1.02) !important;
}
.cardInfoTop {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.cardImg {
  width: 100%;
  /* height: 250px !important; */
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border: none;
  aspect-ratio: 1.2;
}

.cardInfoHover {
  position: absolute;
  padding: 16px;
  width: 100%;
  opacity: 0;
  top: 0;
}

.cardImgHover {
  transition: 0.2s all ease-out;
  /* background-size: contain !important; */
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  position: absolute;
  height: 235px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  top: 0;
}

.cardInfo {
  z-index: 2;
  background-color: #fff;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  padding: 16px 24px 24px 24px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.cardCategory {
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 2px;
  font-weight: 500;
  color: #868686;
}

.cardTitle {
  margin-top: 5px;
  margin-bottom: 4px;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.5;
}
.cardImg,
.cardImgHover {
  background-size: contain !important;
}

.cardBy {
  font-size: 19px;
  font-weight: 600;
}

.cardAuthor {
  font-weight: 600;
  text-decoration: none;
  color: var(--main-color);
}

.card:hover .cardImgHover {
  height: 100%;
  opacity: 1;
}

.card:hover .cardInfo {
  /* background-color: transparent; */
  position: relative;
}

.card:hover .cardInfoHover {
  opacity: 1;
}
.onSale {
  background: var(--main-color);
  color: #fff;
  font-size: 13px;
  border-radius: 50px;
  padding: 7px 13px;
  position: absolute;
  left: 0;
  top: 22px;
  z-index: 100;
  font-weight: 500;
  line-height: 1;
  border-end-start-radius: 0;
  border-start-start-radius: 0;
}
.action {
  display: flex;
  justify-content: space-between;
}
.actionBtn {
  background: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  transition: all 0.1s cubic-bezier(0, 0, 1, 1);
  color: #000;
}
.actionBtn .icon {
  font-size: 18px;
  font-weight: 500;
  position: relative;
}
.actionBtnCart:hover {
  background: var(--active-main-color);
}
.actionBtnIcon {
  width: 40px;
  max-width: 40px;
  min-width: 40px;
  border-radius: 50%;
  border: 1px solid #c0baba;
  margin-inline-start: 10px;
  padding: 0 !important;
}
.actionBtnIcon:hover,
.actionBtnIcon.active {
  background: var(--main-color);
  border: 1px solid var(--main-color);
  color: #fff;
}
.actionBtn:active {
  opacity: 0.6;
}
.actionBtnCart {
  font-size: 15px;
  font-weight: 500;
  background: var(--main-color);
  color: #fff;
  width: 100%;
  border-radius: 50px;
}
.actionBtnCart .icon {
  margin-inline-start: 7px;
  font-size: 15px;
}
.actionBtn.active:disabled {
  opacity: 0.7;
}
.actionBtnFavorite {
  position: relative;
}
.smLoading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  background: #ffffffd9;
  border-radius: 50%;
}
.smLoading * {
  margin: 0 !important;
}
.actionBtnShare {
  position: relative;
  overflow: unset;
}
.actionBtnShare .icon {
  font-weight: 600;
}
div .shareContainer {
  position: absolute;
  background: #fff;
  bottom: 122%;
  right: 0;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  border-radius: 6px;
  padding: 7px 9px !important;
  transition: all 0.2s ease-in-out;
  opacity: 0;
  visibility: hidden;
}
.shareContainer:after {
  content: "";
  position: absolute;
  bottom: -15px;
  right: 0;
  width: 100%;
  height: 15px;
  background: transparent;
}
.actionBtnShare:hover .shareContainer {
  opacity: 1;
  visibility: visible;
}

body[class="rtl-dir"] div .shareContainer {
  right: auto;
  left: 0;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .actionBtn {
    height: 30px;
  }
  .actionBtnIcon {
    width: 30px;
    max-width: 30px;
    min-width: 30px;
    margin-inline-start: 4px;
  }
  .actionBtn .icon {
    font-size: 16px;
  }
  .actionBtnCart {
    font-size: 11px;
  }
  .actionBtnCart .icon {
    margin-inline-start: 4px;
    font-size: 13px;
  }
  .actionBtnFavorite {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    z-index: 30;
  }
  .actionBtnFavorite .icon {
    font-size: 20px;
  }
  .actionBtnFavorite {
    background: none !important;
    border: none !important;
  }
  .actionBtnFavorite:hover {
    background: none !important;
    border: none !important;
    color: var(--main-color) !important;
  }
  .actionBtnFavorite.active {
    background: var(--main-color) !important;
    border: 1px solid var(--main-color) !important;
    color: #fff !important;
  }
  body[class="rtl-dir"] .actionBtnFavorite {
    left: 10px;
    right: auto;
  }
  .shareContainer {
    padding: 4px 11px !important;
  }
}

@media (max-width: 767.98px) {
  .cardInfo {
    padding: 16px 10px 14px 10px;
  }
  .cardTitle {
    font-size: 14px;
  }
  .cardBy {
    font-size: 15px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .actionBtnIcon {
    margin-inline-start: 5px;
  }
  .actionBtnCart {
    font-size: 13px;
  }
  .actionBtnCart .icon {
    margin-inline-start: 5px;
    font-size: 13px;
  }
  .actionBtnIcon {
    width: 35px;
    max-width: 35px;
    min-width: 35px;
  }
  .actionBtn {
    height: 35px;
  }
}

@media (max-width: 991.98px) {
}

@media (max-width: 1199.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
}

@media (min-width: 1400px) {
}
