.item:not(:last-of-type) {
  margin-bottom: 18px;
}
.view {
  width: 90px;
  height: 90px;
  position: relative;
  border: 1px solid #ddd;
  /* border-radius: 6px; */
}
.badge {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: #6e6e6e;
  color: #fff;
  position: absolute;
  right: -9px;
  top: -9px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
}
.image {
  height: 100% !important;
  width: 100% !important;
  object-fit: contain;
}
.details {
  width: calc(100% - 73px);
  padding: 5px 13px 0;
}
.title {
  font-size: 14.5px !important;
  font-weight: 600 !important;
}
.size {
  font-size: 13px !important;
  color: #999 !important;
  font-weight: 600 !important;
  margin-top: 2px;
}
.price {
  font-weight: 600;
  font-size: 15px;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}

@media (max-width: 767.98px) {
  .title {
    font-size: 13px !important;
  }
  .view {
    width: 65px;
    height: 65px;
  }
  .details {
    width: calc(100% - 65px);
  }
  .price {
    font-size: 14px;
  }
  .badge {
    width: 20px;
    height: 20px;
    font-size: 11px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (max-width: 991.98px) {
}

@media (max-width: 1199.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
}

@media (min-width: 1400px) {
}
