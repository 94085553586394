.card {
  border-radius: 12px;
  overflow: hidden;
  border: 1px solid var(--main-border);
  display: flex;
  flex-direction: column;
  height: 99%;
  position: relative;
}
.cardView {
  height: 250px !important;
  display: block;
  overflow: hidden;
}
.viewImg {
  width: 100% !important;
  height: 100% !important;
  object-fit: contain !important;
  object-position: center;
  transition: all 0.15s cubic-bezier(0.42, 0, 1, 1);
}
.card:hover .viewImg {
  transform: scale(1.2);
}
.info {
  padding: 18px;
  height: calc(100% - 250px);
  display: flex;
  flex-direction: column;
}
.title {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 10px;
}
.currentPrice {
  color: var(--main-color);
  font-weight: 600;
  font-size: 17px;
  display: inline-block;
}
.oldPrice {
  color: #c0392b;
  text-decoration: line-through;
  margin-inline-start: 7px;
  display: inline-block;
}
.onSale {
  background: var(--main-color);
  color: #fff;
  font-size: 12px;
  font-weight: 600 !important;
  border-radius: 4px;
  padding: 5px 10px;
  position: absolute;
  left: 15px;
  top: 15px;
  z-index: 100;
  font-weight: 500;
  line-height: 1;
}
.action {
  display: inline-flex;
}
.infoTop {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}

@media (max-width: 767.98px) {
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (max-width: 991.98px) {
}

@media (max-width: 1199.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
}

@media (min-width: 1400px) {
}
