@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700;800&display=swap");

@font-face {
  font-family: "Madani";
  src: url("assets/fonts/madani/bold.ttf") format("ttf");
  src: url("assets/fonts/madani/bold.woff") format("woff");
  src: url("assets/fonts/madani/bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Madani";
  src: url("assets/fonts/madani/900.ttf") format("ttf");
  src: url("assets/fonts/madani/900.woff") format("woff");
  src: url("assets/fonts/madani/900.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Madani";
  src: url("assets/fonts/madani/200.ttf") format("ttf");
  src: url("assets/fonts/madani/200.woff") format("woff");
  src: url("assets/fonts/madani/200.woff2") format("woff2");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Madani Extra";
  src: url("assets/fonts/madani/ExtraBold.ttf") format("ttf");
  src: url("assets/fonts/madani/ExtraBold.woff") format("woff");
  src: url("assets/fonts/madani/ExtraBold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Madani";
  src: url("assets/fonts/madani/300.ttf") format("ttf");
  src: url("assets/fonts/madani/300.woff") format("woff");
  src: url("assets/fonts/madani/300.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Madani";
  src: url("assets/fonts/madani/500.ttf") format("ttf");
  src: url("assets/fonts/madani/500.woff") format("woff");
  src: url("assets/fonts/madani/500.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Madani";
  src: url("assets/fonts/madani/100.ttf") format("ttf");
  src: url("assets/fonts/madani/100.woff") format("woff");
  src: url("assets/fonts/madani/100.woff2") format("woff2");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Madani";
  src: url("assets/fonts/madani/600.ttf") format("ttf");
  src: url("assets/fonts/madani/600.woff") format("woff");
  src: url("assets/fonts/madani/600.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Madani";
  src: url("assets/fonts/madani/Regular.ttf") format("ttf");
  src: url("assets/fonts/madani/Regular.woff") format("woff");
  src: url("assets/fonts/madani/Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

body.rtl-dir {
  /* font-family: "Cairo", sans-serif; */
  font-family: "Madani", sans-serif !important;
  direction: rtl;
}

body.rtl-dir .default-btn .icon-chevron-left {
  transform: scaleX(-1);
}

body.rtl-dir .icon-redirection {
  transform: scaleX(-1);
  display: inline-block;
}

body.rtl-dir .btn-corner:hover .icon-redirection {
  transform: rotateZ(-45deg) scaleX(-1);
}

body.rtl-dir .btn-corner-flat .icon-chevron,
body.rtl-dir .btn-corner-flat .icon {
  transform: scaleX(-1);
}

body.rtl-dir .dropdown-menu {
  left: -2.3rem !important;
  right: auto !important;
}

body.rtl-dir .on-sale {
  right: 15px;
  left: auto;
}

body.rtl-dir .icon-sale {
  transform: scaleX(-1.35) scaleY(1.35);
}

body.rtl-dir .btn-arrow-left {
  right: 15px !important;
  transform: rotateZ(180deg) !important;
}
body.rtl-dir .btn-arrow-right {
  left: 15px !important;
  right: auto !important;
  transform: rotateZ(-180deg) !important;
}
body.rtl-dir .phone-number-container.react-tel-input .selected-flag .arrow {
  left: auto;
  right: 20px;
}
body.rtl-dir .phone-number-container.react-tel-input .form-control {
  padding-left: 0;
  padding-right: 70px;
  text-align: right;
}
/* body.rtl-dir .phone-number-container.react-tel-input .country-list .country {
  text-align: start;
} */

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}

@media (max-width: 767.98px) {
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (max-width: 991.98px) {
  body.rtl-dir .on-sale {
    right: auto !important;
  }
}

@media (max-width: 1199.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
}

@media (min-width: 1400px) {
}
