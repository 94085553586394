.card,
.imgBlock {
  position: relative;
}
.cardBg {
  transition: all 0.1s ease-in-out;
}
.cardImg,
.cardBgImg {
  /* width: 70% !important; */
  object-fit: cover !important;
  aspect-ratio: 1;
  /* background: var(--main-border); */
  border-radius: 50%;
  transition: all 0.1s ease-in-out;
  box-shadow: rgba(14, 63, 126, 0.06) 0px 0px 0px 1px,
    rgba(42, 51, 70, 0.03) 0px 1px 1px -0.5px,
    rgba(42, 51, 70, 0.04) 0px 2px 2px -1px,
    rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px,
    rgba(42, 51, 70, 0.03) 0px 5px 5px -2.5px,
    rgba(42, 51, 70, 0.03) 0px 10px 10px -5px,
    rgba(42, 51, 70, 0.03) 0px 24px 24px -8px;
}
.dropdownTitle {
  font-weight: 600;
  font-size: 15px;
  margin-top: 10px;
}
.dropdownCard {
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  text-align: center;
  position: relative;
}
.dropdownCard:hover,
.cardBg:hover {
  transform: scale(1.07);
  z-index: 1000;
}
.cardBgImg {
  aspect-ratio: 1;
}
.cardBg {
  position: relative;
}
.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.cardBgTitle {
  font-weight: 600;
  color: var(--main-color);
  font-size: 15px;
  text-align: center;
}
div .onSale {
  top: auto !important;
  bottom: 0;
  left: 50% !important;
  transform: translateX(-50%);
  right: auto !important;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .childRowMore .icon {
    font-size: 15px;
  }
}

@media (max-width: 767.98px) {
  .card {
    padding: 5px;
  }
  .title {
    font-size: 15px;
    padding-top: 8px;
  }
  .cardBody {
    padding-top: 6px;
  }
  .childsGrid {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 5px;
  }
  .subCategoryRow {
    display: none;
  }
  .subCategoryRow:nth-child(-n + 3) {
    display: block;
  }
  .cardImg {
    width: 90% !important;
  }
  .cardBgTitle {
    font-size: 13px;
  }
  .bg {
    padding: 8px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  /* .childsGrid {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 5px;
  }
  .subCategoryRow {
    display: none;
  }
  .subCategoryRow:nth-child(-n + 3) {
    display: block;
  } */
  .cardImg {
    width: 85% !important;
  }
}

@media (max-width: 991.98px) {
}

@media (max-width: 1199.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
}

@media (min-width: 1400px) {
}
