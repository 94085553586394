/* .table {
  border-bottom: 1px solid var(--main-border) !important;
  padding-bottom: 30px;
  margin-bottom: 20px;
}
thead th {
  border-bottom: 1px solid var(--main-border) !important;
  padding-bottom: 13px;
  font-weight: 500;
  color: #999;
  font-size: 15px;
}
tbody td {
  padding-top: 20px;
} */
tbody td:first-of-type {
  /* width: 300px !important; */
}
tbody td:nth-of-type(2) {
  /* width: 300px !important; */
  text-align: center;
}
tbody td:last-of-type {
  /* width: 100px !important; */
  text-align: end;
  color: #999;
}
.counter {
  text-align: center;
}
.note {
  max-width: 400px;
  width: 100%;
}
:global .btn-normal {
  font-size: 13px !important;
  color: #999 !important;
}
.itemsCheckout {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-gap: 12.52rem;
  gap: 3rem;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}

@media (max-width: 767.98px) {
  .itemsCheckout {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 1rem;
  }
  .note {
    max-width: 100%;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

@media (max-width: 991.98px) {
}

@media (min-width: 576px) and (max-width: 991.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (max-width: 1199.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
}

@media (min-width: 1400px) {
}
