.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100vh;
}
.details {
  display: flex;
  justify-content: end;
}
.detailsContent {
  display: flex;
  flex-direction: column;
  max-width: 40rem;
  padding: 0 50px;
  width: 100%;
}
.detailsBody {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.items {
  border-left: 2px solid #ededed;
  background: #fafafa;
}
.itemsContent {
  padding: 35px;
  max-width: 38rem;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.detailsHeader {
  padding: 40px 0 20px;
}
.title {
  color: #000 !important;
  font-weight: 600;
  font-size: 18px !important;
}
.text {
  color: #000 !important;
}
.shippingFooter {
  border-top: 1px solid var(--main-border);
  flex-wrap: wrap;
  padding-block: 0.8rem 0.5rem !important;
}
.billRowTitleLarge {
  font-size: 22px;
  font-weight: 600;
}
.billRowValueLarge {
  font-size: 25px;
  font-weight: 600;
}
.itemsHeaderResponsive {
  display: none;
}
.btnToggleItemsRes {
  background: #f6f6f6;
  border: none;
  border-bottom: 1px solid #dfdfdf;
  border-radius: 0;
  padding: 15px;
}
.btnToggleItemsRes:hover,
.btnToggleItemsRes:active {
  background: #f6f6f6;
  border: none;
  border-bottom: 1px solid #dfdfdf;
}
.resToggleTitle {
  font-weight: bold;
  font-size: 13px;
}
.resToggleTitle .icon {
  font-weight: bold;
  font-size: 13px;
  transition: all 0.2s ease-in-out;
}
.resToggleTitle .icon:first-of-type {
  padding-inline-end: 10px;
}
.resToggleTitle .icon:last-of-type {
  padding-inline: 10px;
}
.resTotalPrice {
  font-weight: 600;
  font-size: 17px;
}
.btnToggleItemsRes.active .iconChevron {
  transform: rotateZ(180deg);
}
.billContainer {
  margin-top: 20px;
  border-top: 1px solid var(--main-border);
}
.billRowValue b {
  font-weight: 600;
}
.successColor {
  color: #2ecc71;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}

@media (max-width: 767.98px) {
  .detailsHeader {
    padding-block: 30px;
  }
  .grid {
    display: flex;
    flex-direction: column-reverse;
    height: auto;
    margin: auto;
    max-width: 540px;
  }
  .detailsContent {
    padding-inline: 12px !important;
  }
  .billRowValueLarge {
    font-size: 22px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .grid {
    grid-template-columns: 1fr;
    margin: auto;
    max-width: 500px;
  }
  .detailsContent {
    padding-inline: 12px !important;
  }
  .items {
    border-bottom: 1px solid var(--main-border);
  }
}

@media (max-width: 991.98px) {
  .grid {
    display: flex;
    flex-direction: column-reverse;
    height: auto;
  }
  .items {
    border-bottom: 1px solid var(--main-border);
  }
  .itemsHeaderResponsive {
    display: block;
  }
  .itemsContent {
    -webkit-transition: max-height 0.5s ease-in-out;
    -moz-transition: max-height 0.5s ease-in-out;
    -ms-transition: max-height 0.5s ease-in-out;
    -o-transition: max-height 0.5s ease-in-out;
    transition: max-height 0.5s ease-in-out;
    overflow: hidden;
    max-height: 0;
    position: relative;
    padding: 0;
  }
  .itemsContent.active {
    padding: 15px;
    overflow: visible;
    max-height: unset;
    will-change: max-height;
  }
}

@media (max-width: 1199.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .detailsContent {
    padding: 0 20px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
}

@media (min-width: 1400px) {
}
