.navWrapper {
  padding-block: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
}
.navbarContent,
.navActions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.menuBar,
.backdrop {
  display: none;
}
.item {
  margin-inline-start: 13px;
}
.itemNav {
  padding: 0 !important;
}
.item .icon {
  font-size: 22px;
  color: #555;
}
.logoImg {
  height: 50px !important;
  width: 100px !important;
}
.itemContent {
  display: inline-block;
  position: relative;
}
.badge {
  position: absolute;
  right: -10px;
  background: var(--main-color);
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  top: -9px;
  font-size: 12px;
  font-weight: 600;
  padding: 3px;
}
.mainLinks {
  display: flex;
  align-items: center;
}
.itemLink {
  margin-inline: 10px;
  position: initial;
}
/* .itemLink:after {
  content: "";
  position: absolute;
  top: 26px;
  left: 0;
  width: 100%;
  height: 31px;
  background: #000;
} */
.itemLinkBtn {
  background: none;
  border: none;
  outline: none;
  padding: 0;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  align-items: center;
}
.itemLinkBtn .iconChevron {
  font-weight: 800;
  margin-inline-start: 6px;
  font-size: 13px;
  display: inline-block;
  transition: all 0.2s ease-in-out;
}
.itemLink:hover .iconChevron {
  transform: rotateZ(180deg);
}
.dropdownContainer {
  position: absolute;
  top: 67px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 1100px;
  min-width: 900px;
  width: 100% !important;
  background: #fff;
  z-index: 100;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  border-radius: 8px;
  transition: all 0.15s ease-in-out;
  visibility: hidden;
  opacity: 0;
  padding: 15px 15px;
}
.dropdownContainer:after {
  content: "";
  position: absolute;
  top: -29px;
  left: 0;
  width: 100%;
  height: 34px;
  background: transparent;
}
/* .itemLink:hover .dropdownContainer { */
.dropdownContainer.active {
  visibility: visible;
  opacity: 1;
}
.dropdownHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border-bottom: 1px solid var(--main-border); */
  padding-bottom: 10px;
}
.headerTitle {
  font-weight: 600;
  font-size: 18px;
  color: #444;
}
.dropdownGrid {
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  grid-gap: 12.52rem;
  gap: 25px;
  padding-top: 20px;
}

.dropdownTabs {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.dropTabBtn {
  display: none;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  margin-inline-end: 13px;
  cursor: pointer;
  margin-bottom: 7px;
}
.dropTabLink .icon {
  font-weight: 600;
  margin-inline-start: 8px;
  color: var(--main-color);
}
.linkIcon {
  color: var(--main-color);
  font-size: 17px;
  margin-inline-start: 7px;
  transform: scale(1.5);
  color: #ff3838;
}
.dropTabBtn:nth-child(n + 3) {
  display: flex;
}
.dropTabBtn:hover {
  color: var(--main-color);
}
.logoLink {
  text-align: left;
}
.navLogo,
.logo,
.logoImg,
.logoLink {
  display: flex;
  justify-content: start;
  text-align: left;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}

@media (max-width: 767.98px) {
  .logoImg {
    height: 35px !important;
    object-fit: cover;
  }
  .item {
    margin-inline-start: 10px;
  }
  .item .icon {
    font-size: 20px;
  }
  .navLinks {
    display: none !important;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

@media (max-width: 991.98px) {
  .mainLinks {
    display: none;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .logoImg {
    height: 37px !important;
  }
  .itemLink {
    margin-inline: 8px;
  }
  .dropdownContainer {
    max-width: 700px;
    min-width: 700px;
    padding: 10px 13px;
  }
  .headerTitle {
    font-size: 16px;
  }
}

@media (max-width: 1199.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
}

@media (min-width: 1400px) {
}
