.iconBox {
  padding: 0 20px;
  margin-left: -95px;
}
.icon {
  border: 3px solid #333;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.map {
  height: 190px;
  width: 100%;
}
.contentBox {
  border: 1px solid #d9d9d9;
}
.details {
  padding: 15px 17px;
}
.grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 12.52rem;
  gap: 15px;
}
:global .location-marker-img {
  width: 26px !important;
  height: 26px !important;
}
/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}

@media (max-width: 767.98px) {
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (max-width: 991.98px) {
}

@media (max-width: 1199.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
}

@media (min-width: 1400px) {
}
