.container {
  padding-bottom: 50px;
  border-top: 1px solid var(--main-border);
}
.swiperHeader {
  padding-top: 5px !important;
  padding-inline: 10px !important;
  margin-inline: -10px !important;
  /* width: calc(100% + 20px); */
}
.image {
  height: 100%;
  object-fit: contain !important;
}
.imageLink {
  position: relative;
  display: block;
}
.title {
  /* padding: 20px 0 12px; */
  font-weight: 500;
  color: #5c5c5c;
}
.price {
  font-size: 16px;
  color: #f94c43;
  font-weight: 600;
}
.oldPrice {
  color: #939393;
  font-weight: 400;
  margin: 0 15px;
  text-decoration: line-through;
}
.card {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.top {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.grid {
  position: relative;
}

.action {
  padding-top: 50px;
}
.btnArrowLeft {
  left: 15px !important;
}
.btnArrowRight {
  right: 15px !important;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}

@media (max-width: 767.98px) {
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

@media (max-width: 991.98px) {
}

@media (min-width: 576px) and (max-width: 991.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (max-width: 1199.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
}

@media (min-width: 1400px) {
}
