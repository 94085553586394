.card {
  aspect-ratio: 3;
  position: relative;
  border-radius: 8px;
  overflow: hidden !important;
  background: #f9f9f9;
  padding: 50px;
}
.view {
  display: flex;
  align-items: center;
  justify-content: end;
}
.image {
  width: auto !important;
  height: 300px !important;
  object-fit: contain !important;
  transition: all 0.15s ease-in-out;
  /* object-position: center; */
}
.titleContainer {
  display: flex;
  align-items: center;
}
.title {
  color: var(--main-color);
  font-size: 27px;
  font-weight: 600;
  width: 100%;
  transition: all 0.25s ease-in-out;
}
.onSale {
  background-color: #ff3838;
  color: #fff;
  position: absolute;
  top: 20px;
  left: 20px;
  line-height: 1;
  font-weight: 600;
  z-index: 10;
  font-size: 15px;
  padding: 9px 15px;
  border-radius: 50px;
  display: flex;
  align-items: center;
}
.onSale .icon {
  margin-inline-start: 7px;
  transform: scale(1.45);
}
.viewMore {
  display: inline-flex;
  align-items: center;
  padding: 7px 20px;
  padding-inline-end: 7px;
  border-radius: 50px;
  background: var(--main-color);
  color: #fff;
  line-height: 1;
  font-size: 14px;
  font-weight: 500;
  /* position: absolute;
  top: 15px;
  right: 15px; */
  cursor: pointer;
}
.viewMore .icon {
  margin-inline-start: 10px;
  width: 35px;
  height: 35px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 15px;
  font-weight: 600;
  color: var(--main-color);
  background: #ffff;
}
body[class="rtl-dir"] .viewMore {
  left: 15px;
  right: auto;
}
body[class="rtl-dir"] .viewMore .icon {
  display: inline-flex !important;
}
.cardGrid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 12.52rem;
  gap: 25px;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}

@media (max-width: 767.98px) {
  .title {
    font-size: 15px;
    padding: 11px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (max-width: 991.98px) {
}

@media (max-width: 1199.98px) {
  .viewMore {
    padding: 4px 13px;
    padding-inline-end: 4px;
    font-size: 11px;
    top: 10px;
    right: 10px;
  }
  .viewMore .icon {
    margin-inline-start: 6px;
    width: 24px;
    height: 24px;
    font-size: 11px;
  }
  body[class="rtl-dir"] .viewMore {
    left: 10px;
    right: auto;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
}

@media (min-width: 1400px) {
}
