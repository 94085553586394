.sizeContainer {
  margin-bottom: 25px;
}
.chooseSizeTitle {
  font-size: 15px;
  font-weight: 600;
}
.sizeGrid {
  display: flex;
  flex-wrap: wrap;
}
.sizeCard {
  /* border: 1px solid var(--main-border); */
  /* padding: 10px 7px; */
  /* color: #000; */
  line-height: 1;
  font-size: 14px;
  font-weight: 600;
  width: 50px;
  height: 30px;
  margin-inline-end: 5px;
  border: none;
  position: relative;
}
.sizeCard.active {
}
.sizeCard .icon {
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}

@media (max-width: 767.98px) {
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (max-width: 991.98px) {
}

@media (max-width: 1199.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
}

@media (min-width: 1400px) {
}
