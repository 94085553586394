.swiperHeader {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}
.swiperSlide {
  transition: all 0.3s ease-out !important;
}
.image {
  height: 40rem !important;
  width: 100% !important;
  object-fit: cover !important;
}
.link {
  display: block;
  width: 100%;
  height: 100%;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .image {
    height: 15rem !important;
  }
}

@media (max-width: 767.98px) {
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .image {
    height: 22rem !important;
  }
}

@media (max-width: 991.98px) {
}

@media (min-width: 576px) and (max-width: 991.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (max-width: 1199.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
}

@media (min-width: 1400px) {
}
