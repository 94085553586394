.sizeContainer {
  margin-bottom: 25px;
}
.chooseSizeTitle {
  font-size: 15px;
  font-weight: 600;
}
.sizeGrid {
  display: grid;
  grid-template-columns: repeat(8, minmax(0, 1fr));
  grid-gap: 12.52rem;
  gap: 7px;
}
.sizeCard {
  border: 1px solid var(--main-border);
  padding: 10px 7px;
  color: #000;
  line-height: 1;
  background: transparent;
  font-size: 14px;
  font-weight: 600;
}
.sizeCard.active {
  background: #222;
  border-color: #222;
  color: #fff;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}

@media (max-width: 767.98px) {
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (max-width: 991.98px) {
}

@media (max-width: 1199.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
}

@media (min-width: 1400px) {
}
