.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  z-index: 20;
  display: flex;
  align-items: flex-end;
  transition: all 0.3s ease-in-out, visibility 0.3s ease-in-out;
  overflow-y: hidden;
  visibility: hidden;
  opacity: 0;
  z-index: -50;
}
.backdrop.active {
  z-index: 50;
  opacity: 1;
  visibility: visible;
}
.content {
  padding: 15px 25px 20px;
  width: 100%;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  transition: all 0.3s ease-in-out, visibility 0.3s ease-in-out;
  transform: translateY(110%);
  position: relative;
  z-index: 55;
}
.content.active {
  transform: translateY(0);
}
.formControl {
  width: 100%;
  resize: none;
  border: 1px solid var(--main-border);
  outline: none;
  font-size: 15px;
  padding: 9px;
}
.title {
  font-size: 15px !important;
}
.cancelIcon {
  font-weight: 700;
  font-size: 13px;
  cursor: pointer;
  color: #000;
}
.cancelIcon:hover {
  opacity: 0.7;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}

@media (max-width: 767.98px) {
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (max-width: 991.98px) {
}

@media (max-width: 1199.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
}

@media (min-width: 1400px) {
}
