.cartCard:not(:last-of-type) {
  margin-bottom: 25px;
}
.noteTitle {
  font-size: 14px !important;
  cursor: pointer;
  font-weight: 600;
}
.noteText {
  font-size: 13px;
}
.title {
  cursor: pointer;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}

@media (max-width: 767.98px) {
  .cartCard:not(:last-of-type) {
    margin-bottom: 20px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (max-width: 991.98px) {
}

@media (max-width: 1199.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
}

@media (min-width: 1400px) {
}
