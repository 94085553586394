.card {
  transition: all 0.05s cubic-bezier(0.42, 0, 0.58, 1);
  position: relative;
  overflow: hidden;
  border-radius: 16px;
  aspect-ratio: 0.9;
  display: block;
}
.image {
  transition: all 0.1s cubic-bezier(0.42, 0, 0.58, 1);
  object-fit: cover !important;
  width: 100% !important;
  height: 100% !important;
  /* aspect-ratio: 1; */
}
.action {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  font-size: 25px;
  border: none;
  background: #fff;
  color: var(--main-color);
  transition: all 0.1s cubic-bezier(0.42, 0, 0.58, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%);
}
.card:hover .image {
  transform: scale(1.2);
}
.card:hover .action {
  background-color: var(--main-color);
  color: #fff;
  /* transform: scale(1.2) translateX(-50%); */
}
.overlay {
  background-image: linear-gradient(
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.00709) 10.47%,
    rgba(0, 0, 0, 0.02755) 19.56%,
    rgba(0, 0, 0, 0.06016) 27.58%,
    rgba(0, 0, 0, 0.1037) 34.81%,
    rgba(0, 0, 0, 0.15697) 41.57%,
    rgba(0, 0, 0, 0.21875) 48.13%,
    rgba(0, 0, 0, 0.28782) 54.79%,
    rgba(0, 0, 0, 0.36296) 61.85%,
    rgba(0, 0, 0, 0.44297) 69.61%,
    rgba(0, 0, 0, 0.52662) 78.36%,
    rgba(0, 0, 0, 0.6127) 88.39%,
    rgba(0, 0, 0, 0.7)
  );
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.overlayDetails {
  position: absolute;
  z-index: 10;
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  top: 0;
  left: 0;
}
.detailsText {
  padding-block: 30px;
  text-align: center;
}
.title {
  font-weight: 600;
  font-size: 18px;
  color: #fff;
}
.brand {
  font-size: 16px;
  font-weight: 500;
  color: #c8c7c7;
  text-transform: lowercase;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}

@media (max-width: 767.98px) {
  .card {
    border-radius: 10px;
    aspect-ratio: 0.7;
  }
  .action {
    width: 40px;
    height: 40px;
    font-size: 18px;
  }
  .detailsText {
    padding-block: 16px;
  }
  .title {
    font-size: 14px;
  }
  .brand {
    font-size: 14px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .card {
    border-radius: 10px;
    aspect-ratio: 0.7;
  }
  .action {
    width: 50px;
    height: 50px;
    font-size: 20px;
  }
}

@media (max-width: 991.98px) {
}

@media (max-width: 1199.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .card {
    border-radius: 10px;
    aspect-ratio: 0.7;
  }
  .action {
    width: 50px;
    height: 50px;
    font-size: 20px;
  }
  .title {
    font-size: 16px;
  }
  .brand {
    font-size: 15px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
}

@media (min-width: 1400px) {
}
