@import url("./assets/fonts/iconmoon/icomoon.css");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.cdnfonts.com/css/rounded-elegance");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");
/* @font-face {
  font-family: Futura;
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
  src: url("//wardrobefashion.com/cdn/fonts/futura/futura_n4.df36ce3d9db534a4d7947f4aa825495ed740e410.woff2?h1=d2FyZWRyb2JlLmNv&h2=d2FyZHJvYmVmYXNoaW9uLmNvbQ&h3=Y2hvaWNlbW9kYS5jb20&h4=d2FyZWRyb2JlLWZhc2hpb24uYWNjb3VudC5teXNob3BpZnkuY29t&hmac=6148ed88dee5f39f1a5b86af902162d41fa28c05617139e07393bba42ec757e9")
      format("woff2"),
    url("//wardrobefashion.com/cdn/fonts/futura/futura_n4.6bce24beb4ba1ff4ddeb20f7cd6e2fa513a3d6ec.woff?h1=d2FyZWRyb2JlLmNv&h2=d2FyZHJvYmVmYXNoaW9uLmNvbQ&h3=Y2hvaWNlbW9kYS5jb20&h4=d2FyZWRyb2JlLWZhc2hpb24uYWNjb3VudC5teXNob3BpZnkuY29t&hmac=b037b77acac073081e9b9100938916b66a35ab283c631681644d1096e24bc61d")
      format("woff");
}

@font-face {
  font-family: "Century Gothic";
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
  src: url("//wardrobefashion.com/cdn/fonts/century_gothic/centurygothic_n4.ad57dd3bb953e5ec072f848ba96048738158e9b0.woff2?h1=d2FyZWRyb2JlLmNv&h2=d2FyZHJvYmVmYXNoaW9uLmNvbQ&h3=Y2hvaWNlbW9kYS5jb20&h4=d2FyZWRyb2JlLWZhc2hpb24uYWNjb3VudC5teXNob3BpZnkuY29t&hmac=2c6d5d6ccfe889b3b714f49b133c45859aec2406a2810e863a88b5000fd5e6cc")
      format("woff2"),
    url("//wardrobefashion.com/cdn/fonts/century_gothic/centurygothic_n4.346454bcdd76b0c02e149e968e75218ca6ee803f.woff?h1=d2FyZWRyb2JlLmNv&h2=d2FyZHJvYmVmYXNoaW9uLmNvbQ&h3=Y2hvaWNlbW9kYS5jb20&h4=d2FyZWRyb2JlLWZhc2hpb24uYWNjb3VudC5teXNob3BpZnkuY29t&hmac=aba9f890617cbdc50fb560efb6f844374adb9411fa39e543b06028a7ca084c5e")
      format("woff");
}

@font-face {
  font-family: "Century Gothic";
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
  src: url("//wardrobefashion.com/cdn/fonts/century_gothic/centurygothic_n7.9c88796e9511a086b9c0038824e3fcaa4e104f7d.woff2?h1=d2FyZWRyb2JlLmNv&h2=d2FyZHJvYmVmYXNoaW9uLmNvbQ&h3=Y2hvaWNlbW9kYS5jb20&h4=d2FyZWRyb2JlLWZhc2hpb24uYWNjb3VudC5teXNob3BpZnkuY29t&hmac=2d97a5352eb18304be716911201d2bcefe1342dd19286df4224d9665d46d9719")
      format("woff2"),
    url("//wardrobefashion.com/cdn/fonts/century_gothic/centurygothic_n7.bfbdd76b5b769e73488b72ad922937634199bb91.woff?h1=d2FyZWRyb2JlLmNv&h2=d2FyZHJvYmVmYXNoaW9uLmNvbQ&h3=Y2hvaWNlbW9kYS5jb20&h4=d2FyZWRyb2JlLWZhc2hpb24uYWNjb3VudC5teXNob3BpZnkuY29t&hmac=0600e92a012bebc66219302658461b976731f8c23637f331152b812c6e698562")
      format("woff");
}

@font-face {
  font-family: "Century Gothic";
  font-weight: 400;
  font-style: italic;
  font-display: fallback;
  src: url("//wardrobefashion.com/cdn/fonts/century_gothic/centurygothic_i4.057018ca8ff3e7e0367ab50107a08a8a1691315e.woff2?h1=d2FyZWRyb2JlLmNv&h2=d2FyZHJvYmVmYXNoaW9uLmNvbQ&h3=Y2hvaWNlbW9kYS5jb20&h4=d2FyZWRyb2JlLWZhc2hpb24uYWNjb3VudC5teXNob3BpZnkuY29t&hmac=8b9e56b88739c00e1a4861227f3b4166530da6152de1f5001814c04d6b194d03")
      format("woff2"),
    url("//wardrobefashion.com/cdn/fonts/century_gothic/centurygothic_i4.6bbeca156a45aa967c74d1bdac2f4ec1824dcada.woff?h1=d2FyZWRyb2JlLmNv&h2=d2FyZHJvYmVmYXNoaW9uLmNvbQ&h3=Y2hvaWNlbW9kYS5jb20&h4=d2FyZWRyb2JlLWZhc2hpb24uYWNjb3VudC5teXNob3BpZnkuY29t&hmac=998422c25f1c54c6e2c29821cabc2c372e3b3ec58c7624e92726885f9f3974ba")
      format("woff");
}

@font-face {
  font-family: "Century Gothic";
  font-weight: 700;
  font-style: italic;
  font-display: fallback;
  src: url("//wardrobefashion.com/cdn/fonts/century_gothic/centurygothic_i7.a93d43c32633e83d8663433e7b3a668198503674.woff2?h1=d2FyZWRyb2JlLmNv&h2=d2FyZHJvYmVmYXNoaW9uLmNvbQ&h3=Y2hvaWNlbW9kYS5jb20&h4=d2FyZWRyb2JlLWZhc2hpb24uYWNjb3VudC5teXNob3BpZnkuY29t&hmac=0c5df23b976f06353d84194da1fac7fda197d6417aebffaab7ef3f01adf58a44")
      format("woff2"),
    url("//wardrobefashion.com/cdn/fonts/century_gothic/centurygothic_i7.902eae204ad5c1990bd9339fc5297d31234defc9.woff?h1=d2FyZWRyb2JlLmNv&h2=d2FyZHJvYmVmYXNoaW9uLmNvbQ&h3=Y2hvaWNlbW9kYS5jb20&h4=d2FyZWRyb2JlLWZhc2hpb24uYWNjb3VudC5teXNob3BpZnkuY29t&hmac=d03c2d973423614064da4a8faa7c9a0f284443c6e1445732103212e39103c664")
      format("woff");
}

/* Century Font */
@import url("https://use.typekit.net/oov2wcw.css");
:root {
  --main-border: #e7e7e7;
  --color-light: #939393;
  --main-color: #230b45;
  --active-main-color: #1a0c31;
}
html {
  overflow-x: hidden;
}
body {
  margin: 0;
  /* font-family: "Century Font", sans-serif; */
  /* font-family: century-gothic, sans-serif; */
  /* font-family: "Montserrat", sans-serif !important; */
  font-family: "Manrope", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: auto;
  overflow-x: hidden;
}
a,
a:hover {
  color: var(--main-color);
  text-decoration: none;
}
.lines-1,
.lines-2,
.lines-3,
.lines-4,
.lines-5,
.lines-6,
.lines-7 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.lines-1 {
  -webkit-line-clamp: 1;
}
.lines-2 {
  -webkit-line-clamp: 2;
}
.lines-3 {
  -webkit-line-clamp: 3;
}
.lines-4 {
  -webkit-line-clamp: 4;
}
.lines-5 {
  -webkit-line-clamp: 5;
}
.lines-6 {
  -webkit-line-clamp: 6;
}
.lines-7 {
  -webkit-line-clamp: 7;
}
.max-1-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.main-btn,
.btn,
.btn-normal:after,
.accordion-item,
.radio-bullet,
.radio-bullet-circle,
.btn-corner {
  transition: all 0.2s ease-in-out;
}
.container-sm {
  max-width: 680px;
  width: 100%;
  margin: auto;
}
.red-color {
  color: #f94c43;
}

/* Scroller Bar */
.scroller::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.scroller::-webkit-scrollbar-track {
  background: transparent;
  height: 10px;
  width: 10px;
}

/* Handle */
.scroller::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 50px;
}

/* Handle on hover */
.scroller::-webkit-scrollbar-thumb:hover {
  background: #ccc;
}
/* Scroller Bar */

.form-group-row {
  margin-bottom: 18px;
}

.btn {
  border: none;
  line-height: 1;
  font-size: 15px;
  font-weight: 500;
}

.main-btn {
  padding: 3px 21px;
  min-height: 40px;
  background: linear-gradient(
    90deg,
    rgba(60, 112, 149, 1) 0%,
    rgba(60, 112, 149, 1) 53%,
    rgba(13, 118, 193, 1) 100%
  );
  color: #fff;
  border-radius: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.main-btn:hover {
  opacity: 0.7;
  color: #fff !important;
}
.main-btn:active {
  opacity: 0.4;
  color: #fff !important;
}
.main-btn .icon {
  font-size: 18px;
  margin-right: 7px;
}
.swiper {
  opacity: 0;
  visibility: hidden;
  transition: opacity 1s ease;
  -webkit-transition: opacity 1s ease;
}
.swiper.swiper-initialized {
  visibility: visible;
  opacity: 1;
}
.swiper-pagination {
  display: flex;
  justify-content: flex-end;
  text-align: unset;
  padding: 10px 30px;
}
.swiper-pagination-bullet {
  width: 11px;
  height: 11px;
  border: 2px solid #fff;
  opacity: 1;
  background: none;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12);
}
.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #fff;
}
.btn-arrow.swiper-button-disabled {
  opacity: 0 !important;
}
.btn-arrow {
  overflow: hidden;
  vertical-align: middle;
  line-height: 0;
  transform: scale(1.001);
  transition: background 0.15s ease-in-out, opacity 0.15s ease-in-out,
    transform 0.2s ease-in-out, color 0.2s ease-in-out;
  width: 48px;
  height: 48px;
  background-color: var(--main-color);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 38%;
  cursor: pointer;
  z-index: 30;
}
.btn-arrow:hover {
  background: var(--active-main-color);
}
.btn-arrow:active {
  transform: scale(0.9);
}
.btn-arrow-left {
  left: 15px;
}
.btn-arrow-right {
  right: 15px;
}
.btn-arrow .icon {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}
.btn-arrow-right:hover .icon {
  animation: buttonFromLeftToRight 0.5s ease-in-out forwards;
}

.btn-arrow-left:hover .icon {
  animation: buttonFromRightToLeft 0.5s ease-in-out forwards;
}

body.dir-rtl .btn-arrow-right:hover .icon {
  animation: buttonFromRightToLeft 0.5s ease-in-out forwards;
}

body.dir-rtl .btn-arrow-left:hover .icon {
  animation: buttonFromLeftToRight 0.5s ease-in-out forwards;
}

.btn-arrow-exit:hover .icon {
  animation: buttonFromTopToBottom 0.5s ease-in-out forwards;
}

@keyframes buttonFromTopToBottom {
  0% {
    transform: translateY(0);
  }
  25% {
    opacity: 0;
    transform: translateY(100%);
  }
  50% {
    opacity: 0;
    transform: translateY(-100%);
  }
  75% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes buttonFromLeftToRight {
  0% {
    transform: translate(0);
  }
  25% {
    opacity: 0;
    transform: translate(100%);
  }
  50% {
    opacity: 0;
    transform: translate(-100%);
  }
  75% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes buttonFromRightToLeft {
  0% {
    transform: translate(0);
  }
  25% {
    opacity: 0;
    transform: translate(-100%);
  }
  50% {
    opacity: 0;
    transform: translate(100%);
  }
  75% {
    opacity: 1;
    transform: translate(0);
  }
}
.modal-content {
  border: none;
  border-radius: 16px;
}
.modal-header {
  padding: 20px 20px;
  border: none;
}
.modal-title {
  line-height: 1;
  font-size: 1.3rem;
  font-weight: bold;
}
.modal-body {
  padding: 20px;
}
.modal-share .modal-body {
  padding-top: 10px;
}
.modal-share .btn-close {
  margin: 0;
  padding: 3px;
}
.text-end {
  text-align: end !important;
}
.errors-card {
  color: #f00;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.errors-card .icon {
  margin-right: 5px;
}
.success-msg {
  color: #27ae60;
  margin-bottom: 10px;
  font-size: 18px;
}
.swiper-slide {
  height: auto;
  min-height: auto;
}
.gradient-easing {
  background-image: linear-gradient(
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.00709) 10.47%,
    rgba(0, 0, 0, 0.02755) 19.56%,
    rgba(0, 0, 0, 0.06016) 27.58%,
    rgba(0, 0, 0, 0.1037) 34.81%,
    rgba(0, 0, 0, 0.15697) 41.57%,
    rgba(0, 0, 0, 0.21875) 48.13%,
    rgba(0, 0, 0, 0.28782) 54.79%,
    rgba(0, 0, 0, 0.36296) 61.85%,
    rgba(0, 0, 0, 0.44297) 69.61%,
    rgba(0, 0, 0, 0.52662) 78.36%,
    rgba(0, 0, 0, 0.6127) 88.39%,
    rgba(0, 0, 0, 0.7)
  );
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}
.title-container {
  padding: 60px 0;
}
.title {
  font-size: 21px;
  color: var(--main-color);
  font-weight: 600;
}
.sub-title {
  font-size: 15px;
}
.title-md {
  color: var(--main-color) !important;
  font-weight: 600;
  font-size: 18px !important;
}
.title-regular {
  color: var(--main-color) !important;
  font-weight: 600;
  font-size: 17px !important;
}
.title-sm {
  color: var(--main-color) !important;
  font-weight: 600;
  font-size: 14px !important;
}
.text,
.text-2 {
  font-size: 15px;
  line-height: 1.8;
  color: #666;
}
.text-2 {
  font-size: 18px;
}
.small-text {
  font-size: 14px;
  color: #666;
}
.btn-primary {
  border-radius: 4px;
  font-size: 16px;
  line-height: 1;
  padding: 12px 19px;
  background: var(--main-color) !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}
.btn-primary:hover {
  background: var(--active-main-color) !important;
  box-shadow: none;
}
.btn-primary:active {
  background: var(--active-main-color) !important;
  opacity: 0.6;
}
.btn-light {
  border-radius: 0;
  font-size: 17px;
  padding: 14px 28px;
  font-weight: 500;
}
.btn-normal {
  padding: 0;
  font-size: 16px;
  font-weight: 600;
  position: relative;
  color: #444 !important;
}
.btn-normal:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -4px;
  width: 100%;
  height: 1.5px;
  background: #444;
}
.btn-normal:hover {
  color: var(--main-color) !important;
}
.btn-normal:hover:after {
  width: 0;
}
.btn-normal:active {
  opacity: 0.5;
}
.default-btn {
  color: #999;
  font-weight: 600;
  font-size: 14px;
  padding: 0;
}
.btn-empty {
  display: inline-flex;
  align-items: center;
  height: 100%;
  padding: 0 !important;
  margin: 0 0px !important;
  background: none;
  border: none;
  transition: all 0.3s ease-in-out;
}
.back-btn.default-btn {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
}

.back-btn.default-btn .icon {
  font-weight: bolder;
  font-size: 10px;
  display: flex;
  align-items: center;
  position: relative;
  top: 1px;
  justify-content: center;
  color: var(--main-color);
  margin-inline-end: 7px;
}
.btn-corner {
  border-radius: 50px;
  line-height: 1;
  border: none;
  background: var(--main-color) !important;
  color: #fff !important;
  padding: 11px 20px;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}
.btn-corner .icon {
  font-weight: 600;
  margin-inline-start: 7px;
  transition: all 0.2s ease-in-out;
}
.btn-corner:hover .icon-redirection {
  transform: rotateZ(45deg);
}
.btn-corner:hover,
.btn-corner-flat:hover {
  background: var(--active-main-color) !important;
}
.btn-corner:active,
.btn-corner-flat:active {
  opacity: 0.7;
}
.icon-chevron {
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: var(--main-color);
  background: #fff;
  font-weight: 800;
  margin-inline-start: 7px;
  font-size: 10px;
}
.btn-danger {
  font-size: 13px;
  font-weight: 600;
  border-radius: 50px;
  padding: 7px 13px;
}
.btn-corner-flat {
  border-radius: 50px;
  line-height: 1;
  border: none;
  background: var(--main-color) !important;
  color: #fff !important;
  padding: 4px 4px;
  font-size: 13px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  padding-inline-start: 15px;
}
.btn-corner-flat .icon-chevron,
.btn-corner-flat .icon {
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: var(--main-color);
  background: #fff;
  font-weight: 800;
  margin-inline-start: 7px;
  font-size: 11px;
}
/* Button */

/* Status */
.status {
  line-height: 1;
  padding: 7px 16px;
  background: #333;
  color: #fff;
  border-radius: 50px;
  display: flex;
  align-items: center;
  font-weight: 600;
  line-height: 1;
  font-size: 13px;
}
.bullet {
  width: 6px;
  height: 6px;
  background: #fff;
  border-radius: 50%;
  margin-inline-end: 5px;
}
.pending {
  background: #ffefe1;
  color: #ff9f43;
}
.pendingBullet {
  background: #ff9f43;
}

.canceled,
.cancelled {
  background: #f9ddde;
  color: #e73b41;
}
.canceledBullet,
.cancelledBullet {
  background: #e73b41;
}

.delivered {
  background: #dcf6e8;
  color: #28c76f;
}
.deliveredBullet {
  background: #28c76f;
}

.confirmed {
  background: #cfedfc;
  color: #4fb8ea;
}
.confirmedBullet {
  background: #4fb8ea;
}
/* Status */

/* Input */
.normal-input {
  width: 100%;
  resize: none;
  border: 1px solid var(--main-border);
  outline: none;
  font-size: 15px;
  padding: 9px;
}
/* Input */

/* Dropdown */
.dropdown {
  display: inline-block;
}
.dropdown-menu {
  padding: 12px 0 !important;
  text-align: center;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  min-width: 100px;
  border-radius: 0;
  top: 18px !important;
  right: -2.3rem !important;
  left: auto !important;
}
.dropdown-item {
  padding: 4px 10px;
  font-size: 14px;
  position: relative;
}
.dropdown-menu:after {
  content: "";
  position: absolute;
  top: -19px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent #fff transparent;
  /* box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px; */
  filter: drop-shadow(0 -0.5px 1px rgba(54, 54, 54, 0.2));
}
.dropdown-item:active {
  background-color: #222;
}
.dropdown .btn {
  background: none;
  color: var(--main-color);
  border-radius: 0;
  padding: 0;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 13px;
  display: flex;
  align-items: center;
}
.white .dropdown .btn {
  color: #fff;
}
.dropdown .btn .icon {
  font-weight: 800;
  font-size: 13px;
  display: flex;
  align-items: center;
  margin-left: 7px;
  margin-right: 7px;
  padding-top: 1px;
}
.dropdown .btn:active {
  background: none !important;
  color: var(--main-color);
}
.white .dropdown .btn:active {
  color: #fff;
}
/* Dropdown */

/* Side Drawer */
.drawer-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.drawer-header .title {
  font-size: 20px;
}
.drawer-header {
  padding-top: 15px;
  padding-bottom: 15px;
  position: relative;
}
.drawer-body {
  border-top: 1px solid var(--main-border);
  border-bottom: 1px solid var(--main-border);
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  padding-top: 30px;
  padding-bottom: 30px;
}
.drawer-footer {
  padding-top: 15px;
  padding-bottom: 15px;
}
.drawer-header,
.drawer-body,
.drawer-footer {
  padding-inline: 25px;
}
.drawer-close-btn {
  font-weight: 600;
  font-size: 15px;
}
/* Side Drawer */

/* MUI Inputs */
.MuiInputBase-root.MuiOutlinedInput-root {
  border-radius: 0 !important;
}
.input-box {
  margin-bottom: 15px;
}
.MuiFormHelperText-root.Mui-error {
  margin: 5px 0 0 0 !important;
  font-size: 14px;
}
.MuiInputBase-input {
  border: 1px solid #e7e7e7;
  font-size: 15px !important;
}
.MuiFormLabel-root {
  line-height: 1.8 !important;
}
.Mui-focused.MuiFormLabel-root {
  color: var(--main-color) !important;
  line-height: 1.9 !important;
}
.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: var(--main-color) !important;
}
/* MUI Inputs */

/* Items Terrible */
.cards-grid-double {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 12.52rem;
  gap: 3rem;
}
.cards-grid-terrible {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 12.52rem;
  gap: 3rem;
}
.cards-grid-four {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap: 12.52rem;
  gap: 0.8rem;
}
.card-item {
  width: 100%;
  margin-bottom: 15px;
  position: relative;
}
.card-item-image {
  width: 100%;
  max-width: 100%;
  object-fit: cover;
  aspect-ratio: 0.85;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);
}
.card-item-image-double {
  aspect-ratio: 0.7;
  /* height: 900px; */
}
.card-item-image-terrible {
  aspect-ratio: 0.7;
  /* height: 662px; */
}
.card-item-image-four {
  aspect-ratio: 0.7;
  /* height: 500px; */
  /* max-width: 720px; */
}
.card-item-title {
  padding: 18px 0 12px;
  font-weight: 500;
  color: #333;
}
.card-item-price {
  font-size: 16px;
  font-weight: 600;
}
.card-item-price-red {
  color: #f94c43;
  font-weight: 600;
  font-size: 14px;
}
.card-item-price-gray {
  color: #939393;
}
.card-item-old-price {
  color: #939393;
  font-weight: 400;
  margin-inline-start: 15px;
  text-decoration: line-through;
}
.card-item-sale {
  position: absolute;
  top: 13px;
  left: 13px;
  background: #222;
  color: #fff;
  padding: 7px 12px;
  line-height: 1;
  font-size: 12px;
  font-weight: 600;
  box-shadow: 0px 3px 6px 0px rgba(140, 149, 159, 0.15);
}
.card-item,
.card-item-image {
  transition: all 0.5s ease-in-out;
}
/* Items Terrible */

/* Contact Table */
.contact-table {
  border: 1px solid #dedede;
  padding: 0 15px;
}
.contact-title {
  font-size: 13px;
  color: #999;
  min-width: 100px;
}
.contact-value {
  padding: 0 8px;
  margin-inline-end: auto;
  font-size: 13px;
  font-weight: 600;
  white-space: nowrap;
}
.contact-action button {
  font-size: 11px !important;
}
.contact-action button:after {
  bottom: -4px;
}
.contact-table-row {
  padding: 10px 0;
}
.contact-table-row:not(:last-of-type) {
  border-bottom: 1px solid #dedede;
}
/* Contact Table */

/* Page Structure */
.page-content {
  padding: 50px 0 70px;
}
.divider {
  margin-top: 28px;
  margin-bottom: 28px;
}
/* Page Structure */

/* Accordion */
.accordion-item.active {
  background: #f4f4f4;
}
.accordion-item.active .accordion-header-title {
  border: 1px solid var(--main-color);
}
.accordion-header-title {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 62px;
  padding: 10px 17px;
  border: 1px solid transparent;
}
.accordion-button,
.accordion-button:focus,
.accordion-button:hover,
.accordion-button:active {
  box-shadow: none !important;
  border: none !important;
  outline: none !important;
  background: none !important;
  padding: 0 !important;
}
.accordion-button::after {
  display: none;
}
.accordion-radio-block {
  width: 100%;
  height: 100%;
}
.radio-bullet {
  width: 18px;
  height: 18px;
  background: transparent;
  border-radius: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--main-border);
}
.radio-bullet-circle {
  width: 42%;
  height: 42%;
  background-color: #fff;
  border-radius: 50%;
}
.accordion-item.active .radio-bullet {
  background: #333;
  border: 1px solid #333;
}
.radio-label {
  padding: 0 10px;
  font-weight: 500;
  color: var(--main-color);
  font-size: 14px;
}
.accordion-item.active .radio-label {
  font-weight: 600;
}
.accordion-item {
  border-radius: 0 !important;
}
.accordion-alert {
  font-size: 14px;
}
.accordion-body {
  padding: 17px !important;
}
.accordion-logo {
  height: 36px;
  background: #fff;
  border: 1px solid #e5e5e5;
  padding: 0px 7px;
  margin: 0 3px;
}
.accordion-logos {
  margin-inline-start: auto;
}
/* Accordion */

/* Table */
.main-table {
  border-bottom: 1px solid var(--main-border) !important;
  padding-bottom: 30px;
  margin-bottom: 20px;
  overflow-x: auto;
}
.main-table thead th {
  border-bottom: 1px solid var(--main-border) !important;
  padding-bottom: 9px;
  font-weight: 500;
  color: #999;
  font-size: 12px;
}
.main-table tbody td {
  padding-top: 20px;
}
.main-table td,
.main-table th {
  white-space: nowrap !important;
}
.main-table tbody td,
.main-table thead th {
  padding-inline: 30px;
}
.main-table tbody td:first-child,
.main-table tbody td:last-child,
.main-table thead th:first-child,
.main-table thead th:last-child {
  padding-inline: 0;
}
.table-text {
  font-size: 14px;
  color: #666;
  display: flex;
  justify-content: flex-start;
}
.table-card-view {
  width: 120px !important;
  height: 140px !important;
}
.table-card-view .image {
  object-fit: cover !important;
  width: 100% !important;
  height: 100% !important;
}
.table-card-details {
  width: calc(100% - 120px);
  /* padding-left: 13px; */
  padding-inline-start: 13px;
  display: flex;
  flex-direction: column;
}
.table-card-details .title {
  font-size: 16px !important;
}
.table-card-details .text {
  font-size: 13px !important;
  color: var(--main-color);
  font-weight: 500;
}
.table-card-details .top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
}
.table-card-details .btn-normal {
  font-size: 12px !important;
  margin-bottom: 7px;
}
/* Table */

/* Sweetalert */
.swal2-confirm.btn-primary,
.swal2-confirm.btn-success {
  display: inline-block;
  border-radius: 0;
  font-size: 17px;
  padding: 11px 23px;
  outline: none !important;
  box-shadow: none !important;
}
.swal2-cancel.btn-outline-danger:hover {
  background: none;
  color: #dc3545;
  outline: none !important;
}
/* Sweetalert */

/* React Hot Toast */
.react-hot-toast {
  font-size: 15px !important;
  color: var(--main-color) !important;
  letter-spacing: 0.14px !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  border-radius: 4px !important;
}
/* React Hot Toast */

/* Mixer Grid */
.mixer-grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 10px;
}
.mixer-grid > * {
  aspect-ratio: 1;
}

.mixer-grid > *:nth-child(1),
.mixer-grid > *:nth-child(2),
.mixer-grid > *:nth-child(6),
.mixer-grid > *:nth-child(7),
.mixer-grid > *:nth-child(11),
.mixer-grid > *:nth-child(12),
.mixer-grid > *:nth-child(16),
.mixer-grid > *:nth-child(17),
.mixer-grid > *:nth-child(21),
.mixer-grid > *:nth-child(22),
.mixer-grid > *:nth-child(26),
.mixer-grid > *:nth-child(27) {
  grid-column: span 6;
}
.mixer-grid > *:nth-child(3),
.mixer-grid > *:nth-child(4),
.mixer-grid > *:nth-child(5),
.mixer-grid > *:nth-child(8),
.mixer-grid > *:nth-child(9),
.mixer-grid > *:nth-child(10),
.mixer-grid > *:nth-child(13),
.mixer-grid > *:nth-child(14),
.mixer-grid > *:nth-child(15),
.mixer-grid > *:nth-child(18),
.mixer-grid > *:nth-child(19),
.mixer-grid > *:nth-child(20),
.mixer-grid > *:nth-child(23),
.mixer-grid > *:nth-child(24),
.mixer-grid > *:nth-child(25),
.mixer-grid > *:nth-child(28),
.mixer-grid > *:nth-child(29),
.mixer-grid > *:nth-child(30) {
  grid-column: span 4;
  display: block;
}

.mixer-grid-6 {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 10px;
}
.mixer-grid-6 > * {
  aspect-ratio: 1;
}
.mixer-grid-6 > *:nth-child(1),
.mixer-grid-6 > *:nth-child(2) {
  grid-column: span 12;
  aspect-ratio: 2.3;
}
.mixer-grid-6 > *:nth-child(3),
.mixer-grid-6 > *:nth-child(4),
.mixer-grid-6 > *:nth-child(5),
.mixer-grid-6 > *:nth-child(6) {
  grid-column: span 6;
}
.mixer-grid-6 > *:nth-child(5),
.mixer-grid-6 > *:nth-child(6) {
  aspect-ratio: 0.7;
}
/* Mixer Grid */

/* On Sale */
.on-sale {
  background-color: #ff3838;
  color: #fff;
  position: absolute;
  top: 15px;
  left: 15px;
  line-height: 1;
  font-weight: 600;
  z-index: 10;
  font-size: 14px;
  padding: 6px 15px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.on-sale .icon {
  margin-inline-start: 7px;
  transform: scale(1.35);
}
.on-sale.sm {
  font-size: 14px;
  padding: 6px 15px;
}
.on-sale.sm .icon {
  margin-inline-start: 4px;
  transform: scale(1.1);
}
/* On Sale */

/* Success & Warning Messages */
.notyf__toast {
  box-shadow: 0px 0px 12px -4px rgba(0, 0, 0, 0.1);
}
.notyf__ripple {
  background: #fff !important;
}
.notyf__toast {
  position: relative;
  padding-left: 23px;
  border-radius: 6px;
}
.notyf__toast:before {
  position: absolute;
  content: "";
  left: 7px;
  width: 5px;
  height: 86%;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50px;
  background: #000;
  z-index: 10;
}
.notyf__toast.notyf__toast--success:before {
  background: #3dc763 !important;
}
.notyf__toast.notyf__toast--error:before {
  background: #ed3d3d !important;
}
.notyf__icon--success {
  color: #fff !important;
  background: #3dc763 !important;
  outline: 5px solid #3dc7631c !important;
}
.notyf__icon--error {
  color: #fff !important;
  background: #ed3d3d !important;
  outline: 5px solid #ed3d3d1c !important;
}
.notyf__toast--success .notyf__message {
  color: #000 !important;
}
.notyf__toast--error .notyf__message {
  color: #000 !important;
}
.notify-toast-btn {
  cursor: pointer;
  font-size: 15px;
}
/* Success & Warning Messages */

/* Phone Number */
.phone-number-container.react-tel-input .form-control {
  font-size: 1rem;
  min-height: 46px;
  border-radius: 0 !important;
  border-color: rgb(0 0 0 / 24%) !important;
  padding-left: 70px;
  width: 100%;
}
.phone-number-container.react-tel-input .form-control:focus,
.phone-number-container.react-tel-input .form-control:active,
.phone-number-container.react-tel-input .form-control:hover {
  outline: none !important;
  box-shadow: none !important;
}
.phone-number-container.react-tel-input .selected-flag {
  padding: 0;
  border-radius: 0;
  display: flex;
  justify-content: center;
  width: 56px;
}
.phone-number-container.react-tel-input .flag-dropdown {
  border-radius: 0 !important;
}
.phone-number-container.react-tel-input .country-list .flag {
  margin-inline-end: 7px;
}
.phone-number-container.react-tel-input .country-list .country {
  text-align: start !important;
}
/* Phone Number */

/* Errors */
.data-error {
  color: #d32f2f;
  margin: 5px 0 0 0 !important;
  font-size: 14px;
  font-weight: 500;
}
.data-error-border.phone-number-container.react-tel-input .form-control,
.data-error-border.phone-number-container.react-tel-input .flag-dropdown {
  border-color: #d32f2f !important;
}
/* Errors */

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .cards-grid-four {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 8px;
  }
}

@media (max-width: 767.98px) {
  /* Scroller Bar */
  .scroller::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  /* Track */
  .scroller::-webkit-scrollbar-track {
    background: transparent;
    height: 10px;
    width: 10px;
    width: 0;
    height: 0;
  }

  /* Handle */
  .scroller::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 50px;
    width: 0px;
    height: 0px;
  }

  /* Handle on hover */
  .scroller::-webkit-scrollbar-thumb:hover {
    background: transparent;
    width: 0px;
    height: 0px;
  }
  /* Scroller Bar */

  .title-container {
    padding: 40px 0 12px;
  }
  .title {
    font-size: 16px;
  }
  .text,
  .text-2 {
    font-size: 14px;
  }
  .cards-grid-terrible {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 10px;
  }
  .card-item-title {
    padding: 8px 0 7px;
    font-size: 13px;
    font-weight: 600;
  }
  .card-item-image-terrible {
    height: unset;
    aspect-ratio: 0.7;
    min-height: 15rem;
  }
  .card-item-image-four {
    height: auto;
    aspect-ratio: 0.7;
    /* min-height: 30rem; */
  }
  .card-item-price {
    font-size: 13px;
  }
  .btn-arrow {
    width: 40px;
    height: 40px;
  }
  .btn-arrow .icon {
    font-size: 13px;
  }
  .btn-arrow-right {
    right: 10px;
  }
  .btn-arrow-left {
    left: 10px;
  }
  .page-content {
    padding: 25px 0 30px;
  }
  .cards-grid-double {
    gap: 10px;
  }
  .css-usc8si-MuiPagination-root .MuiPaginationItem-root {
    font-size: 13px;
    padding: 6px !important;
    min-width: 25px;
  }
  .drawer-header,
  .drawer-body,
  .drawer-footer {
    padding-inline: 18px;
  }
  .drawer-body {
    padding-block: 17px;
  }
  .contact-table {
    padding: 0 15px;
  }
  .contact-title {
    min-width: 70px;
  }
  .contact-action {
    width: 100%;
    display: flex;
    justify-content: end;
    margin-top: 5px;
  }
  .checkout-actions {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .checkout-actions > div {
    width: 100%;
  }
  .checkout-back-btn {
    margin-top: 15px;
  }
  .mixer-grid-6,
  .mixer-grid {
    grid-gap: 5px;
  }
  .mixer-grid-6 > * {
    aspect-ratio: 0.7;
  }
  .mixer-grid > * {
    grid-column: span 6 !important;
    aspect-ratio: 0.7;
  }
  .btn-corner {
    padding: 9px 14px;
    font-size: 12px;
  }
  .btn-corner .icon {
    font-size: 10px;
  }
  .on-sale {
    top: 10px;
    left: 10px;
    font-size: 12px !important;
    padding: 4px 11px !important;
  }
  .card-item-old-price {
    margin-inline-start: 0;
    display: inline-flex;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .cards-grid-four {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 15px;
  }
}

@media (max-width: 991.98px) {
}

@media (min-width: 576px) and (max-width: 991.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .btn-corner {
    padding: 10px 17px;
    font-size: 13px;
  }
  .btn-arrow-right {
    right: 20px;
  }
  .btn-arrow-left {
    left: 20px;
  }
  .title-container {
    padding: 50px 0 30px;
  }
  .cards-grid-terrible {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1.5rem;
  }
  .card-item-image-terrible {
    height: auto;
    aspect-ratio: 0.7;
    min-height: 30rem;
  }
  .title {
    font-size: 19px;
  }
  .card-item-image-four {
    height: auto;
    aspect-ratio: 0.7;
  }
  .cards-grid-four {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 1rem;
  }
  .on-sale {
    top: 13px;
    left: 13px;
    font-size: 12px;
    padding: 6px 11px;
  }
  .on-sale .icon {
    margin-inline-start: 7px;
    transform: scale(1.25);
  }
}

@media (max-width: 1199.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .btn-arrow-right {
    right: 20px;
  }
  .btn-arrow-left {
    left: 20px;
  }
  .title {
    font-size: 18px;
  }

  .cards-grid-four {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 15px;
  }
  .on-sale {
    top: 13px;
    left: 13px;
    font-size: 13px;
    padding: 7px 12px;
  }
  .on-sale .icon {
    margin-inline-start: 7px;
    transform: scale(1.25);
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
}

@media (min-width: 1400px) {
  .container-fluid {
    padding-left: 50px;
    padding-right: 50px;
  }
}
