.text {
  color: var(--main-color) !important;
}
.addressesGrid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 12.52rem;
  gap: 13px;
}
.addressCard {
  border: 2px solid #eee;
  display: flex;
  padding: 15px;
  border-radius: 6px;
  cursor: pointer;
}
.acIcon {
  width: 35px;
}
.acIcon .icon {
  font-size: 21px;
  color: #9b9b9b;
}
.acDetails {
  width: calc(100% - 35px);
  display: flex;
  flex-direction: column;
  flex: 1;
}
.detailsBody {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.acTitle {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.4;
  margin-bottom: 5px;
  color: #333;
}
.acText {
  font-size: 15px;
}
.selectAction {
  flex: 0;
  display: flex;
}
.selectBtn {
  background: #ececec;
  color: #cdcdcd;
  border: none;
  padding: 7px 12px;
  font-size: 14px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}
.selectBtn .icon {
  margin-inline-start: 7px;
}
.selectBtnActive {
  background: var(--main-color);
  color: #fff;
}
.selectCardActive {
  border: 2px solid var(--main-color) !important;
}
.acPhone {
  font-size: 14px;
}
.emptyAddressesLink {
  color: rgb(0, 51, 255);
  text-decoration: underline;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}

@media (max-width: 767.98px) {
  .addressesGrid {
    gap: 6px;
  }
  .acIcon {
    display: none;
  }
  .acDetails {
    width: 100%;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (max-width: 991.98px) {
}

@media (max-width: 1199.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
}

@media (min-width: 1400px) {
}
