.container {
  box-shadow: 1px 1px var(--main-border), -1px -1px var(--main-border);
}
.block {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 12px;
}
.container .btnEmpty {
  display: inline-flex;
  align-items: center;
  height: 100%;
  padding: 0 !important;
  margin: 0 0px !important;
  background: none;
  border: none;
  transition: all 0.3s ease-in-out;
}
.sortGrid,
.sortList,
.sortFilter {
  padding: 13px 20px;
  display: flex;
  align-items: center;
  height: 100%;
}
.sortGrid {
  border-inline-end: 1px solid var(--main-border);
}
div .sortGridBtn {
  opacity: 0.2;
  margin-inline-end: 14px !important;
}
.sortGridBtn.active {
  opacity: 1;
}
.sortGridBtn .icon {
  font-size: 22px;
  display: inline-block;
  margin: 0 !important;
}
.left,
.right {
  display: flex;
  align-items: center;
}
.sortList {
  height: 100%;
  display: flex;
  align-items: center;
  border-inline-start: 1px solid var(--main-border);
}
.sortFilter {
  height: 100%;
  border-inline-start: 1px solid var(--main-border);
}
.sortFilterContainer {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  max-width: 390px;
  height: 100%;
  background: #fff;
  box-shadow: 2px 0 10px #36363633, -2px 0 10px #36363633;
  transition: all 0.5s ease-in-out;
  z-index: 1000;
  transform: translateX(110%);
}
.sortFilterContainer.active {
  transform: translateX(0);
}
.sortBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  visibility: hidden;
  opacity: 0;
  background: #363636;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  z-index: -50;
}
.sortBackdrop.active {
  z-index: 50;
  opacity: 0.5;
  visibility: visible;
}
.sortContent {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.sortHeader {
  padding: 20px 0;
  position: relative;
}
.sortTitle {
  font-size: 20px;
  font-weight: 500;
}
.sortExit {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
}
.sortExit .icon {
  font-weight: 600;
  font-size: 15px;
}
.sortBody {
  border-top: 1px solid var(--main-border);
  border-bottom: 1px solid var(--main-border);
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  padding: 30px 25px;
}
.sortBody,
.sortFooter {
  padding: 30px 25px;
}
.sortFooter {
  padding-top: 15px;
  padding-bottom: 15px;
}
.sortBodyTitle {
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 12px;
}
.sortBody > div:not(:last-of-type) {
  margin-bottom: 30px;
}
div .sortItemTitle,
.sortItemTitle:after {
  transition: all 0.15s ease-in-out !important;
}
.filterItemsGrid,
.filterColorsGrid,
.filterItemsGridDiscounts {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 12.52rem;
  gap: 20px;
  grid-column-gap: 12px;
  padding-top: 10px;
}
.filterColorsGrid {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}
.filterItemsGridDiscounts {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}
.colorCard {
  width: 27px;
  height: 27px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
}
.colorCardIcon {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  opacity: 0;
}
div .colorCard.active .colorCardIcon {
  opacity: 1 !important;
}
div .sortItemTitle {
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 0 !important;
  display: block !important;
  height: auto !important;
  position: relative;
  text-align: start;
}
.sortItemTitle:after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;

  transform: translateY(-50%);
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #000;
  opacity: 0;
}
.sortItemTitle.active {
  font-weight: 600;
  padding-inline-start: 12px !important;
}
.sortItemTitle.active:after {
  opacity: 1 !important;
}
.priceRangeInputs {
  width: 100%;
}
.priceRangeInputs,
.sliderInput,
.currency {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sliderInput {
  width: 45%;
  border: 2px solid var(--main-border);
  padding: 0 10px;
}
.sliderInput input {
  width: 50%;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  height: 32px;
  border: none;
  outline: none;
  color: #666;
}
.sliderInput input:disabled {
  background: none !important;
}
.currency {
  font-weight: 600;
  line-height: 1;
  font-size: 14px;
  color: #666;
}

/* RTL Directions */
/* body.rtl-direction .sortGrid {
  border-left: 1px solid var(--main-border);
  border-right: none;
} */
/* body.rtl-direction div .sortGridBtn {
  margin-left: 14px !important;
  margin-right: 0 !important;
} */
/* RTL Directions */
body[class="rtl-dir"] .sortItemTitle:after {
  left: auto;
  right: 0;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .sortFilterContainer {
    max-width: 300px;
  }
}

@media (max-width: 767.98px) {
  .sortGrid {
    padding-inline: 0;
  }
  .sortGridBtn .icon {
    font-size: 17px;
  }
  .sortGrid,
  .sortList,
  .sortFilter {
    padding-block: 10px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

@media (max-width: 991.98px) {
}

@media (min-width: 576px) and (max-width: 991.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (max-width: 1199.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
}

@media (min-width: 1400px) {
}
